define("shared/components/form-elements/text-area/text-area", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/utils", "@ember/object", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _service, _utils, _object, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="text-input-container {{@customClass}} relative">
    <Textarea maxlength={{@maxLength}}
      @value={{@text}}
      placeholder={{@placeholder}}
      class="input-text h-full {{if @text 'active'}} {{if this.validInput 'border-bp-base-secondary' 'border-bp-base-brand'}} {{unless @inputLabel 'no-label'}}"
      autocomplete="off"
      {{on 'keyup' this.onKeyup}}
      {{on 'change' this.validate}}
      {{on "input" this.validateInputFormat}}/>
    <label class="input-label {{if @text 'active'}}">{{@inputLabel}}</label>
    {{#if @maxLength}}
      <div class="text-xs absolute right-0 md:mt-2">
        <span class="text-bp-text-icons-subdued">{{this.characterCount}}/</span>
        <span class="text-bp-text-icons-subdued">{{@maxLength}}</span>
      </div>
    {{/if}}
    {{#unless this.validInput}}
      <div class="text-bp-base-brand text-sm break-words mb-2">{{this.validationErrorText}}</div>
    {{/unless}}
  </div>
  
  */
  {
    "id": "1VdC5ks9",
    "block": "[[[10,0],[15,0,[29,[\"text-input-container \",[30,1],\" relative\"]]],[12],[1,\"\\n  \"],[8,[39,0],[[16,\"maxlength\",[30,2]],[16,\"placeholder\",[30,3]],[16,0,[29,[\"input-text h-full \",[52,[30,4],\"active\"],\" \",[52,[30,0,[\"validInput\"]],\"border-bp-base-secondary\",\"border-bp-base-brand\"],\" \",[52,[51,[30,5]],\"no-label\"]]]],[24,\"autocomplete\",\"off\"],[4,[38,3],[\"keyup\",[30,0,[\"onKeyup\"]]],null],[4,[38,3],[\"change\",[30,0,[\"validate\"]]],null],[4,[38,3],[\"input\",[30,0,[\"validateInputFormat\"]]],null]],[[\"@value\"],[[30,4]]],null],[1,\"  \"],[10,\"label\"],[15,0,[29,[\"input-label \",[52,[30,4],\"active\"]]]],[12],[1,[30,5]],[13],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[10,0],[14,0,\"text-xs absolute right-0 md:mt-2\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"text-bp-text-icons-subdued\"],[12],[1,[30,0,[\"characterCount\"]]],[1,\"/\"],[13],[1,\"\\n      \"],[10,1],[14,0,\"text-bp-text-icons-subdued\"],[12],[1,[30,2]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[41,[51,[30,0,[\"validInput\"]]],[[[1,\"    \"],[10,0],[14,0,\"text-bp-base-brand text-sm break-words mb-2\"],[12],[1,[30,0,[\"validationErrorText\"]]],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@customClass\",\"@maxLength\",\"@placeholder\",\"@text\",\"@inputLabel\"],false,[\"textarea\",\"if\",\"unless\",\"on\"]]",
    "moduleName": "shared/components/form-elements/text-area/text-area.hbs",
    "isStrictMode": false
  });
  let TextAreaComponent = _exports.default = (_class = class TextAreaComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "validInput", _descriptor2, this);
    }
    get characterCount() {
      return (0, _utils.isBlank)(this.args.text) ? 0 : this.args.text.length;
    }
    validateInputFormat(event) {
      if (this.args.regexPattern) {
        event.target.value = event.target.value.replace(this.args.regexPattern, '');
      }
    }
    onKeyup() {
      if (this.args.onKeyup) {
        this.args.onKeyup();
      }
      this.validate();
    }
    validate() {
      if (this.args.onValidate) {
        this.args.onValidate();
      }
      this.validInput = this.validateInput();
    }
    validateInput() {
      if (this.args.mandatory) {
        return (0, _utils.isPresent)(this.args.text) && this.args.text?.length > 0;
      }
      return true;
    }
    get validationErrorText() {
      if (this.args.mandatory) {
        return this.intl.t('llc.inputs.text_validation');
      }
      return null;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "validInput", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "validateInputFormat", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "validateInputFormat"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onKeyup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onKeyup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "validate"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TextAreaComponent);
});