define("shared/components/form-elements/text-input/text-input", ["exports", "ember-concurrency/-private/async-arrow-runtime", "@ember/component", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/service", "@ember/utils", "ember-concurrency", "shared/llc/us-states", "@ember/template-factory"], function (_exports, _asyncArrowRuntime, _component, _component2, _object, _tracking, _service, _utils, _emberConcurrency, _usStates, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="text-input-container {{@customClass}} {{this.executeValidation}}">
    <Input  {{did-insert this.update}} {{did-update this.validateAndUpdateInput @text}}
      {{on 'keyup' this.onKeyup}}
      {{on 'change' this.validate}}
      {{on 'input' this.validateInputFormat}}
      id={{@id}}
      @type={{if @type @type 'text'}}
      @value={{@text}}
      ...attributes
      class="
        {{if this.isDisabled 'bg-bp-surface-subdued'}}
        input-text h-13
        {{if (and @text (not this.isDisabled) (not this.prefilled)) 'active'}}
        {{if this.validInput 'border-bp-base-secondary' 'border-bp-base-brand'}}
        {{unless @inputLabel 'no-label'}}
        {{if this.prefilled 'prefilled'}}
      "
      autocomplete="off"
      data-testing-id={{@dataTestingId}}
      pattern={{this.pattern}}
      placeholder={{@placeholder}}
      disabled={{this.isDisabled}}
      id={{@elementId}}
      min={{@min}}
      max={{@max}}/>
    <label class="input-label {{if @text 'active'}}">{{@inputLabel}}</label>
    {{#unless (or this.validInput @hideValidationMsg)}}
      <div class="text-bp-base-brand text-sm break-words">{{this.validationErrorText}}</div>
    {{/unless}}
    {{#if @disclaimer}}
      <div class="text-bp-text-icons-main text-sm">{{@disclaimerText}}</div>
    {{/if}}
  </div>
  
  */
  {
    "id": "7jPae206",
    "block": "[[[10,0],[15,0,[29,[\"text-input-container \",[30,1],\" \",[30,0,[\"executeValidation\"]]]]],[12],[1,\"\\n  \"],[8,[39,0],[[16,1,[30,2]],[17,3],[16,0,[29,[\"\\n      \",[52,[30,0,[\"isDisabled\"]],\"bg-bp-surface-subdued\"],\"\\n      input-text h-13\\n      \",[52,[28,[37,2],[[30,4],[28,[37,3],[[30,0,[\"isDisabled\"]]],null],[28,[37,3],[[30,0,[\"prefilled\"]]],null]],null],\"active\"],\"\\n      \",[52,[30,0,[\"validInput\"]],\"border-bp-base-secondary\",\"border-bp-base-brand\"],\"\\n      \",[52,[51,[30,5]],\"no-label\"],\"\\n      \",[52,[30,0,[\"prefilled\"]],\"prefilled\"],\"\\n    \"]]],[24,\"autocomplete\",\"off\"],[16,\"data-testing-id\",[30,6]],[16,\"pattern\",[30,0,[\"pattern\"]]],[16,\"placeholder\",[30,7]],[16,\"disabled\",[30,0,[\"isDisabled\"]]],[16,1,[30,8]],[16,\"min\",[30,9]],[16,\"max\",[30,10]],[4,[38,5],[[30,0,[\"update\"]]],null],[4,[38,6],[[30,0,[\"validateAndUpdateInput\"]],[30,4]],null],[4,[38,7],[\"keyup\",[30,0,[\"onKeyup\"]]],null],[4,[38,7],[\"change\",[30,0,[\"validate\"]]],null],[4,[38,7],[\"input\",[30,0,[\"validateInputFormat\"]]],null]],[[\"@type\",\"@value\"],[[52,[30,11],[30,11],\"text\"],[30,4]]],null],[1,\"\\n  \"],[10,\"label\"],[15,0,[29,[\"input-label \",[52,[30,4],\"active\"]]]],[12],[1,[30,5]],[13],[1,\"\\n\"],[41,[51,[28,[37,8],[[30,0,[\"validInput\"]],[30,12]],null]],[[[1,\"    \"],[10,0],[14,0,\"text-bp-base-brand text-sm break-words\"],[12],[1,[30,0,[\"validationErrorText\"]]],[13],[1,\"\\n\"]],[]],null],[41,[30,13],[[[1,\"    \"],[10,0],[14,0,\"text-bp-text-icons-main text-sm\"],[12],[1,[30,14]],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@customClass\",\"@id\",\"&attrs\",\"@text\",\"@inputLabel\",\"@dataTestingId\",\"@placeholder\",\"@elementId\",\"@min\",\"@max\",\"@type\",\"@hideValidationMsg\",\"@disclaimer\",\"@disclaimerText\"],false,[\"input\",\"if\",\"and\",\"not\",\"unless\",\"did-insert\",\"did-update\",\"on\",\"or\"]]",
    "moduleName": "shared/components/form-elements/text-input/text-input.hbs",
    "isStrictMode": false
  });
  let TextInputComponent = _exports.default = (_class = class TextInputComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "formValidation", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "heap", _descriptor3, this);
      _initializerDefineProperty(this, "validInput", _descriptor4, this);
      _initializerDefineProperty(this, "edited", _descriptor5, this);
      _defineProperty(this, "validateLlcNameErrorType", null);
      _defineProperty(this, "sendSelectionFromDropdownDataEvent", (0, _asyncArrowRuntime.buildTask)(() => ({
        context: this,
        generator: function* (errMsg) {
          this.heap.track('google_address_error', {
            error: errMsg.string
          });
        }
      }), null, "sendSelectionFromDropdownDataEvent", "drop"));
      if (this.args.validatePrefilled && (0, _utils.isPresent)(this.args.text)) {
        this.validate();
      }
    }
    get executeValidation() {
      if (this.args.validateNow) {
        this.validate();
      }
      return true;
    }
    get getStateName() {
      return _usStates.default[this.args.state];
    }
    scrollToElement() {
      if (!this.id) {
        return;
      }
      const el = document.getElementById(this.id);
      if (!el) {
        return;
      }
      let rect = el.getBoundingClientRect();
      const isInView = rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
      rect.right <= (window.innerWidth || document.documentElement.clientWidth); /* or $(window).width() */
      if (!isInView) {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
    }
    get id() {
      return this.args.id || this.args.elementId;
    }
    businessDistinctNamesAndRestrictedWords(name, duplicate, oldName, oldAlternativeName, state) {
      return this.checkNameValidity({
        name,
        state,
        distinctCheck: () => this.formValidation.namesAreDistinct(name, duplicate, oldName, oldAlternativeName),
        errorTypeForDistinct: 'distinctNames'
      });
    }
    llcNameNonDuplicateAndRestrictedWords(name, duplicate, state) {
      return this.checkNameValidity({
        name,
        state,
        distinctCheck: () => this.formValidation.llcNameNonDuplicate(name, duplicate),
        errorTypeForDistinct: 'duplication'
      });
    }
    checkNameValidity({
      name,
      state,
      distinctCheck,
      errorTypeForDistinct
    }) {
      this.validateLlcNameErrorType = null;
      const restrictedWords = this.formValidation.checkRestrictedWords(name, state);
      if (restrictedWords !== '') {
        this.validateLlcNameErrorType = 'restrictedWords';
        this.heap.track('llc_post_purchase_name_availability_not_valid_name', {
          pre_error_type: 'restricted_state_words',
          state: state,
          business_name: name,
          business_name_restricted_word: restrictedWords
        });
        return false;
      }
      if (!distinctCheck()) {
        this.validateLlcNameErrorType = errorTypeForDistinct;
        this.heap.track('llc_post_purchase_name_availability_not_valid_name', {
          pre_error_type: errorTypeForDistinct,
          state: state,
          business_name: name
        });
        return false;
      }
      return true;
    }
    getLlcNameErrorMessage(name, state) {
      const errorMessages = {
        restrictedWords: () => this.getRestrictedWordsErrorMessage(name, state),
        duplication: () => this.intl.t('llc.inputs.duplication.business_name'),
        distinctNames: () => this.intl.t(`llc.orders.resolve_name_availability.error.${this.formValidation.NewBusinessNameErrorType(name)}`)
      };
      return this.validateLlcNameErrorType ? errorMessages[this.validateLlcNameErrorType]?.() : null;
    }
    getRestrictedWordsErrorMessage(name, state) {
      const restrictedWords = this.formValidation.checkRestrictedWords(name, state);
      const wordCount = restrictedWords.split(',').length;
      const wordLabelKey = wordCount === 1 ? 'llc.inputs.restricted_words_error.word_label_singular' : 'llc.inputs.restricted_words_error.word_label_plural';
      const wordLabel = this.intl.t(wordLabelKey).toString();
      return this.intl.t('llc.inputs.restricted_words_error.error_message', {
        word: wordLabel,
        words_list: restrictedWords,
        state: this.getStateName
      });
    }
    get validationErrorText() {
      this.scrollToElement();
      switch (this.args.validType) {
        case 'email':
        case 'emailWithKeyup':
          return this.intl.t('llc.inputs.email_validation');
        case 'illegalWords':
          return this.intl.t('llc.inputs.illegal_words_validation');
        case 'phone':
          return this.intl.t('llc.inputs.phone_validation');
        case 'onlyDigits':
          return this.intl.t('llc.inputs.only_digits_validation');
        case 'ssn':
          return this.intl.t('llc.inputs.ssn_validation.' + this.formValidation.ssnErrorType(this.args.text));
        case 'retypedSsn':
          return this.intl.t('llc.inputs.ssn_validation.' + this.formValidation.ssnErrorType(this.args.text, this.args.ssn, this.args.company));
        case 'ssn-ein':
          return this.intl.t('llc.inputs.ssn_ein_validation');
        case 'city':
          return this.intl.t('llc.inputs.text_validation');
        case 'ein_city':
          return this.intl.t('llc.inputs.city_validation.' + this.formValidation.cityErrorType(this.args.text));
        case 'country':
          return this.intl.t('llc.inputs.text_validation');
        case 'county':
          return this.intl.t('llc.inputs.text_validation');
        case 'ein_county':
          return this.intl.t('llc.inputs.' + this.formValidation.countyErrorType(this.args.text));
        case 'name':
          return this.intl.t('llc.inputs.name_validation');
        case 'fullName':
          return this.intl.t('llc.inputs.full_name.' + this.formValidation.fullNameErrorType(this.args.text));
        case 'name_illegalWords':
          return this.nameOrIllegalWords;
        case 'email_illegalWords':
          return this.emailOrIllegalWords;
        case 'illegalWords_differentThanBusinessName':
          return this.illegalWordsOrDifferentThanBusinessName;
        case 'text':
          return this.intl.t('llc.inputs.text_validation');
        case 'employeeAmount':
          return this.intl.t('llc.inputs.employee_amount_validation');
        case 'positiveNumberOrZero':
          return this.intl.t('llc.inputs.positive_number_or_zero_validation');
        case 'address':
          return this.validatePoBoxAddress;
        case 'fincenAddress':
          return this.intl.t('llc.inputs.fincen_address_validation');
        case 'url':
          return this.intl.t('llc.inputs.url_validation');
        case 'password':
          return this.intl.t('account.errors.password_missing');
        case 'confirmPassword':
          return this.intl.t('account.errors.password_not_match');
        case 'postalCode':
          return this.intl.t('llc.inputs.zip_code_validation.' + this.formValidation.postalCodeErrorType(this.args.text, this.args.country));
        case 'llcNameNonDuplicate':
          return this.intl.t('llc.inputs.duplication.' + this.formValidation.duplicateErrorType(this.args.inputLabel));
        case 'llcNameNonDuplicateAndRestrictedWords':
          {
            return this.getLlcNameErrorMessage(this.args.text, this.args.state);
          }
        case 'businessDistinctNamesAndRestrictedWords':
          return this.getLlcNameErrorMessage(this.args.text, this.args.state);
        case 'llcName':
          return this.intl.t('llc.inputs.llc_name_validation.' + this.formValidation.llcNameErrorType(this.args.text));
        case 'webfileNumber':
          return this.intl.t('llc.inputs.webfile_number.' + this.formValidation.webFileNumberErrorType(this.args.text));
        case 'einFeinNumber':
          return this.intl.t('llc.inputs.ein_fein_number.' + this.formValidation.einFeinNumberErrorType(this.args.text));
        case 'txSosNumber':
          return this.intl.t('llc.inputs.tx_sos_number.' + this.formValidation.txSosNumberErrorType(this.args.text));
        case 'ownerPercentageInteger':
          return this.intl.t('llc.inputs.ownership_percentage.' + this.formValidation.ownershipPercentageIntegerErrorType(this.args.text));
        case 'irsName':
          return this.intl.t('llc.inputs.ra_name_validation');
        case 'physicalAddress':
          {
            this.sendSelectionFromDropdownDataEvent.perform(this.intl.t('llc.inputs.physical_address_validation'));
            return this.intl.t('llc.inputs.physical_address_validation');
          }
        case 'emptyPhysicalAddress':
          {
            this.sendSelectionFromDropdownDataEvent.perform(this.intl.t('llc.inputs.empty_physical_address_validation'));
            return this.intl.t('llc.inputs.empty_physical_address_validation');
          }
        case 'outOfBlockedState':
          {
            const errorKey = this.args.isBusinessAddress ? 'llc.inputs.address_out_of_blocked_state' : 'llc.inputs.out_of_blocked_state';
            this.sendSelectionFromDropdownDataEvent.perform(this.intl.t(errorKey, {
              state: this.args.stateForAddressValidation
            }));
            return this.intl.t(errorKey, {
              state: this.args.stateForAddressValidation
            });
          }
        case 'businessStarted':
          return this.intl.t('llc.inputs.business_started_validation');
        case 'naicsCode':
          return this.intl.t('llc.funnel.naics_code.validation');
        case 'atLeast18Years':
          return this.intl.t('llc.inputs.date_above_18');
        case 'physicalAddressAndNotPoBoxPmb':
          {
            this.sendSelectionFromDropdownDataEvent.perform(this.intl.t('llc.inputs.physical_address_validation'));
            return this.validatePoBoxAddressRa;
          }
        default:
          return null;
      }
    }
    get pattern() {
      return this.args.validType === 'phone' ? '\\d*' : '';
    }
    get validatePoBoxAddress() {
      if ((0, _utils.isEmpty)(this.args.text)) {
        return this.intl.t('llc.inputs.text_validation');
      } else if (this.args.needValidatePoBoxForState) {
        if (this.formValidation.isPartOfStatesThatValidatePoBox(this.args.stateForAddressValidation) && !this.formValidation.validPoBoxAddress(this.args.text)) {
          return this.intl.t('llc.inputs.po_box_address_validation');
        } else {
          return this.intl.t('llc.inputs.text_validation');
        }
      } else if (!this.formValidation.validPoBoxAddress(this.args.text)) {
        return this.intl.t('llc.inputs.po_box_address_generic_validation');
      } else {
        return this.intl.t('llc.inputs.text_validation');
      }
    }
    extractInvalidWord(text) {
      const lowerCase = text.toLowerCase();
      const keywords = ['pmb', 'po box', 'p.o. box', 'p.o box', 'p.o.b', 'private mail box'];
      for (const keyword of keywords) {
        if (lowerCase.includes(keyword)) {
          return keyword;
        }
      }
      return text;
    }
    get validatePoBoxAddressRa() {
      if (!(0, _utils.isEmpty)(this.args.text)) {
        if (!this.formValidation.validPoBoxAddress(this.args.text, true)) {
          const word = this.extractInvalidWord(this.args.text);
          return this.intl.t('llc.inputs.validate_po_box_address_ra', {
            word
          });
        } else if (!this.formValidation.validateNoVa(this.args.text, this.args.purchasedMailbox)) {
          return this.intl.t('llc.inputs.validate_va_for_registered_agent');
        } else if (!this.formValidation.validPhysicalAddress(this.args.text)) {
          return this.intl.t('llc.inputs.physical_address_validation');
        }
      } else {
        return this.intl.t('llc.inputs.text_validation');
      }
      return this.intl.t('llc.inputs.text_validation');
    }
    validateIsAdult(inputDate) {
      if ((0, _utils.isBlank)(inputDate)) {
        return false;
      }
      const date = new Date(inputDate);
      const eighteenYearsAgo = new Date();
      eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
      return date < eighteenYearsAgo;
    }
    get nameOrIllegalWords() {
      const text = this.args.text;
      if (!this.formValidation.validName(text)) {
        return this.intl.t('llc.inputs.name_validation');
      } else if (!this.formValidation.validWithoutIllegalWords(text)) {
        return this.intl.t('llc.inputs.illegal_words_validation');
      }
      return true;
    }
    get emailOrIllegalWords() {
      const text = this.args.text;
      if (!this.formValidation.validEmail(text)) {
        return this.intl.t('llc.inputs.email_validation');
      } else if (!this.formValidation.validWithoutIllegalWords(text)) {
        return this.intl.t('llc.inputs.illegal_words_validation');
      }
      return true;
    }
    get illegalWordsOrDifferentThanBusinessName() {
      const text = this.args.text;
      const businessName = this.args.businessName;
      if (!this.formValidation.validWithoutIllegalWords(text)) {
        return this.intl.t('llc.inputs.illegal_words_validation');
      } else if (!this.formValidation.isTextDifferentThanBusinessName(text, businessName)) {
        return this.intl.t('llc.inputs.business_name_validation');
      }
      return true;
    }
    get isDisabled() {
      if ((0, _utils.isPresent)(this.args.disabled)) {
        return this.args.disabled;
      }
      return false;
    }
    get prefilled() {
      return this.args.prefilled && !this.edited || false;
    }
    validate() {
      if (this.args.onValidate) {
        this.args.onValidate();
      }
      this.edited = true;
      this.validInput = this.validateInput();
    }
    validateInputFormat(event) {
      this.validate();
      if (event && this.args.regexPattern) {
        event.target.value = event.target.value.replace(this.args.regexPattern, '');
      }
    }
    update(e) {
      if (this.args.validType === 'phone' && (0, _utils.isPresent)(this.args.text)) {
        let x = e.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        e.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
      } else if (this.args.validType === 'einFeinNumber' && (0, _utils.isPresent)(this.args.text)) {
        let formattedValue = e.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,7})/);
        e.value = formattedValue ? formattedValue[1] + (formattedValue[2] ? '-' + formattedValue[2] : '') : '';
      }
      if (this.args.validType === 'ssn' && (0, _utils.isPresent)(this.args.text)) {
        e.value = e.value.replace(/[^\d]+/g, '');
      }
    }
    validateAndUpdateInput(event) {
      this.update(event);
      this.validate();
    }
    onKeyup() {
      if (this.args.onKeyup) {
        this.args.onKeyup();
      }
      if (this.args.validType !== 'phone' && this.args.validType !== 'email' && this.args.validType !== 'password' && this.args.validType !== 'confirmPassword') {
        this.validate();
      }
    }
    pobValidationForStates() {
      if (!this.formValidation.validPoBoxAddress(this.args.text)) {
        return false;
      } else if (this.formValidation.validPoBoxAddress(this.args.text)) {
        return this.formValidation.validText(this.args.text);
      }
    }
    validAddress() {
      if (this.args.needValidatePoBoxForState) {
        if (this.formValidation.isPartOfStatesThatValidatePoBox(this.args.stateForAddressValidation)) {
          return this.pobValidationForStates();
        } else if (!this.formValidation.isPartOfStatesThatValidatePoBox(this.args.stateForAddressValidation)) {
          return this.formValidation.validText(this.args.text);
        }
      } else if (['address', 'physicalAddress'].includes(this.args.validType)) {
        return this.formValidation.validPoBoxAddress(this.args.text) && this.formValidation.validText(this.args.text);
      } else {
        return this.formValidation.validText(this.args.text);
      }
    }
    validAddressRa() {
      if (['physicalAddressAndNotPoBoxPmb'].includes(this.args.validType)) {
        return this.formValidation.validPoBoxAddress(this.args.text, true) && this.formValidation.validText(this.args.text) && this.formValidation.validateNoVa(this.args.text, this.args.purchasedMailbox);
      } else {
        return this.formValidation.validText(this.args.text);
      }
    }
    validEmployeeAmount(number) {
      return number > 0;
    }
    validateInput() {
      if (this.args.optional && (0, _utils.isBlank)(this.args.text)) {
        return true;
      }
      switch (this.args.validType) {
        case 'phone':
          return this.formValidation.validPhoneNumber(this.args.text);
        case 'email':
        case 'emailWithKeyup':
          return this.formValidation.validEmail(this.args.text);
        case 'onlyDigits':
          return this.formValidation.validOnlyDigits(this.args.text);
        case 'ssn':
          return this.formValidation.validSSNNum(this.args.text) && this.formValidation.validSsnAsWarning(this.args.text);
        case 'retypedSsn':
          return this.formValidation.ssnMatch(this.args.ssn, this.args.text) && this.formValidation.validSsnAsWarning(this.args.text);
        case 'ssn-ein':
          return this.formValidation.validSSNEINNum(this.args.text);
        case 'name':
          return this.formValidation.validName(this.args.text);
        case 'fullName':
          return this.formValidation.validFullName(this.args.text);
        case 'irsName':
          return this.formValidation.validIrsResponsibilityPartyName(this.args.text);
        case 'address':
          return this.validAddress();
        case 'fincenAddress':
          return this.formValidation.validFincenAddressPattern(this.args.text);
        case 'ein_city':
          return this.formValidation.validEinCity(this.args.text);
        case 'county':
          return this.formValidation.validCounty(this.args.text);
        case 'ein_county':
          return this.formValidation.validEinCounty(this.args.text);
        case 'physicalAddress':
          return this.validAddress() && this.formValidation.validPhysicalAddress(this.args.text);
        case 'employeeAmount':
          return this.validEmployeeAmount(this.args.text);
        case 'positiveNumberOrZero':
          return this.formValidation.validPositiveNumberOrZero(this.args.text);
        case 'url':
          return this.formValidation.validUrl(this.args.text);
        case 'password':
          return this.formValidation.validPassword(this.args.text);
        case 'name_illegalWords':
          return this.formValidation.validWithoutIllegalWords(this.args.text) && this.formValidation.validName(this.args.text);
        case 'email_illegalWords':
          return this.formValidation.validWithoutIllegalWords(this.args.text) && this.formValidation.validEmail(this.args.text);
        case 'illegalWords':
          return this.formValidation.validWithoutIllegalWords(this.args.text);
        case 'illegalWords_differentThanBusinessName':
          return this.formValidation.validWithoutIllegalWords(this.args.text) && this.formValidation.isTextDifferentThanBusinessName(this.args.text, this.args.businessName);
        case 'confirmPassword':
          return this.formValidation.validConfirmPassword(this.args.password, this.args.confirmPassword);
        case 'postalCode':
          return this.formValidation.validPostalCode(this.args.text, this.args.country);
        case 'llcName':
          return this.formValidation.validText(this.args.text) && this.formValidation.validLlcName(this.args.text);
        case 'webfileNumber':
          return this.formValidation.validWebfileNumber(this.args.text);
        case 'einFeinNumber':
          return this.formValidation.validEinFeinNumber(this.args.text);
        case 'txSosNumber':
          return this.formValidation.validTxSosNumber(this.args.text);
        case 'ownerPercentageInteger':
          return this.formValidation.validOwnershipPercentageInteger(this.args.text);
        case 'businessStarted':
          return this.formValidation.validBusinessStarted(this.args.text);
        case 'naicsCode':
          return this.formValidation.validNaicsCode(this.args.text);
        case 'llcNameNonDuplicate':
          return this.formValidation.llcNameNonDuplicate(this.args.text, this.args.duplicate);
        case 'llcNameNonDuplicateAndRestrictedWords':
          return this.llcNameNonDuplicateAndRestrictedWords(this.args.text, this.args.duplicate, this.args.state);
        case 'businessDistinctNamesAndRestrictedWords':
          return this.businessDistinctNamesAndRestrictedWords(this.args.text, this.args.duplicate, this.args.oldName, this.args.oldAlternativeName, this.args.state);
        case 'atLeast18Years':
          return this.validateIsAdult(this.args.text);
        case 'skip':
          return true;
        case 'physicalAddressAndNotPoBoxPmb':
          return this.validAddressRa() && this.formValidation.validPhysicalAddress(this.args.text);
        default:
          return this.formValidation.validText(this.args.text);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "formValidation", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "heap", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "validInput", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "edited", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "validate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "validate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validateInputFormat", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "validateInputFormat"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "update", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "update"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validateAndUpdateInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "validateAndUpdateInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onKeyup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onKeyup"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TextInputComponent);
});