define("shared/components/marketing/audience", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="max-w-auto mx-auto">
    <h2 class="text-center text-bp-text-icons-main text-2xl lg:text-4xl leading-7 lg:leading-8 font-gazpacho mb-2">{{t (concat this.translationKey '.audience.title')}}</h2>
    <div class="text-center">{{t (concat this.translationKey '.audience.sub')}}</div>
    <div class="flex flex-col lg:flex-row gap-5 justify-center">
      {{#each this.items as |item|}}
        <div class="flex flex-col items-center lg:py-6 py-8">
          <img src="/images/llc/marketing/{{item}}.png" class="w-20" alt={{t 'logo'}}>
          <div class="lg:w-100 lg:w-70">
            <h3 class="text-center text-bp-text-icons-main font-bold lg:text-xl text-base leading-6 mx-auto lg:mt-5 mb-2">
              {{t (concat this.translationKey '.audience.' item '.title' )}}
            </h3>
            <div class="text-center text-bp-text-icons-main leading-6 text-base mx-auto">
              {{t (concat this.translationKey '.audience.' item '.description')}}
            </div>
          </div>
        </div>
      {{/each}}
    </div>
  </div>
  
  */
  {
    "id": "GPLlEG0G",
    "block": "[[[10,0],[14,0,\"max-w-auto mx-auto\"],[12],[1,\"\\n  \"],[10,\"h2\"],[14,0,\"text-center text-bp-text-icons-main text-2xl lg:text-4xl leading-7 lg:leading-8 font-gazpacho mb-2\"],[12],[1,[28,[35,0],[[28,[37,1],[[30,0,[\"translationKey\"]],\".audience.title\"],null]],null]],[13],[1,\"\\n  \"],[10,0],[14,0,\"text-center\"],[12],[1,[28,[35,0],[[28,[37,1],[[30,0,[\"translationKey\"]],\".audience.sub\"],null]],null]],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-col lg:flex-row gap-5 justify-center\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"items\"]]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"flex flex-col items-center lg:py-6 py-8\"],[12],[1,\"\\n        \"],[10,\"img\"],[15,\"src\",[29,[\"/images/llc/marketing/\",[30,1],\".png\"]]],[14,0,\"w-20\"],[15,\"alt\",[28,[37,0],[\"logo\"],null]],[12],[13],[1,\"\\n        \"],[10,0],[14,0,\"lg:w-100 lg:w-70\"],[12],[1,\"\\n          \"],[10,\"h3\"],[14,0,\"text-center text-bp-text-icons-main font-bold lg:text-xl text-base leading-6 mx-auto lg:mt-5 mb-2\"],[12],[1,\"\\n            \"],[1,[28,[35,0],[[28,[37,1],[[30,0,[\"translationKey\"]],\".audience.\",[30,1],\".title\"],null]],null]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"text-center text-bp-text-icons-main leading-6 text-base mx-auto\"],[12],[1,\"\\n            \"],[1,[28,[35,0],[[28,[37,1],[[30,0,[\"translationKey\"]],\".audience.\",[30,1],\".description\"],null]],null]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"item\"],false,[\"t\",\"concat\",\"each\",\"-track-array\"]]",
    "moduleName": "shared/components/marketing/audience.hbs",
    "isStrictMode": false
  });
  class AudienceComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "items", Object.freeze(['personalized', 'marketing', 'grow']));
    }
    get translationKey() {
      return 'marketing.landing_page';
    }
  }
  _exports.default = AudienceComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AudienceComponent);
});