define("shared/components/llc/llc-order-display-name", ["exports", "@ember/component", "@glimmer/component", "shared/llc/us-states", "@ember/utils", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _usStates, _utils, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.hasFullDetails}}
    {{this.companyName}}, {{this.state}}
  {{else}}
    {{this.displayOrderId}}
  {{/if}}
  
  */
  {
    "id": "3Fd1x2t6",
    "block": "[[[41,[30,0,[\"hasFullDetails\"]],[[[1,\"  \"],[1,[30,0,[\"companyName\"]]],[1,\", \"],[1,[30,0,[\"state\"]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[30,0,[\"displayOrderId\"]]],[1,\"\\n\"]],[]]]],[],false,[\"if\"]]",
    "moduleName": "shared/components/llc/llc-order-display-name.hbs",
    "isStrictMode": false
  });
  let LlcOrderDisplayNameComponent = _exports.default = (_class = class LlcOrderDisplayNameComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "intl", _descriptor, this);
    }
    get state() {
      return _usStates.default[this.args.company.state];
    }
    get companyName() {
      if (this.args.isInXpTest) {
        return `${this.args.company.name} ${this.intl.t('llc_title')}`;
      } else {
        return this.args.company.type === 'initial-company' ? this.args.company.name : this.args.company.businessName;
      }
    }
    get hasFullDetails() {
      return (0, _utils.isPresent)(this.companyName) && (0, _utils.isPresent)(this.args.company.state);
    }
    get displayOrderId() {
      const id = (0, _utils.isPresent)(this.args.llcProduct) ? this.args.llcProduct.id : this.args.company.id.replace(/[-,\D]/g, '').slice(0, 5);
      return `LLC-34-${id.padStart(3, '0')}`;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LlcOrderDisplayNameComponent);
});