define("shared/llc/funnels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TRUIC = _exports.TOP_TEM_INCORPORATE = _exports.NAMECHEAP = _exports.GODADDY = _exports.GLOBAL = _exports.FORBES = _exports.ARF = void 0;
  const GLOBAL = _exports.GLOBAL = 'global';
  const TRUIC = _exports.TRUIC = 'truic';
  const GODADDY = _exports.GODADDY = 'godaddy';
  const NAMECHEAP = _exports.NAMECHEAP = 'namecheap';
  const FORBES = _exports.FORBES = 'forbes';
  const ARF = _exports.ARF = 'arf';
  const TOP_TEM_INCORPORATE = _exports.TOP_TEM_INCORPORATE = 'top_ten_incorporate';
  var _default = _exports.default = {
    GLOBAL,
    TRUIC,
    ARF,
    GODADDY,
    NAMECHEAP,
    FORBES,
    TOP_TEM_INCORPORATE
  };
});