define("shared/components/business-license/header", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="header flex flex-col items-start">
    <div class="flex items-center p-3 bg-white shadow w-full h-16">
      <div class="flex w-9/20 md:w-1/2">
        <a href="/" class="logo">
          <img class="hidden md:block mr-xs h-10 filter-black" src="/images/logo_red.svg" alt={{t 'tailor_brands'}}>
        </a>
      </div>
      <div class="flex w-11/20 md:w-1/2 md:-ml-4 columns-2">
        <a href="/" class="logo absolute top-1 py-2.5">
          <img class="h-10 md:hidden filter-black" src="/images/logo_small_red.svg" alt={{t 'tailor_brands'}}>
        </a>
      </div>
      <Global::LanguageBar @customClass={{if this.screenSizes.isSmall '' 'ml-4'}}/>
    </div>
  </div>
  
  */
  {
    "id": "PJVhWrVx",
    "block": "[[[10,0],[14,0,\"header flex flex-col items-start\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex items-center p-3 bg-white shadow w-full h-16\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"flex w-9/20 md:w-1/2\"],[12],[1,\"\\n      \"],[10,3],[14,6,\"/\"],[14,0,\"logo\"],[12],[1,\"\\n        \"],[10,\"img\"],[14,0,\"hidden md:block mr-xs h-10 filter-black\"],[14,\"src\",\"/images/logo_red.svg\"],[15,\"alt\",[28,[37,0],[\"tailor_brands\"],null]],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"flex w-11/20 md:w-1/2 md:-ml-4 columns-2\"],[12],[1,\"\\n      \"],[10,3],[14,6,\"/\"],[14,0,\"logo absolute top-1 py-2.5\"],[12],[1,\"\\n        \"],[10,\"img\"],[14,0,\"h-10 md:hidden filter-black\"],[14,\"src\",\"/images/logo_small_red.svg\"],[15,\"alt\",[28,[37,0],[\"tailor_brands\"],null]],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,1],null,[[\"@customClass\"],[[52,[30,0,[\"screenSizes\",\"isSmall\"]],\"\",\"ml-4\"]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"t\",\"global/language-bar\",\"if\"]]",
    "moduleName": "shared/components/business-license/header.hbs",
    "isStrictMode": false
  });
  let HeaderComponent = _exports.default = (_class = class HeaderComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "screenSizes", _descriptor, this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "screenSizes", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, HeaderComponent);
});