define("shared/components/marketing/includes", ["exports", "@ember/component", "@ember/service", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _service, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="flex flex-col mx-auto">
    <div class="font-bold text-left mb-4">{{t 'marketing.includes.title'}}</div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-2 gap-x-4">
      {{#each this.items as |item|}}
        <div class="flex gap-2 mb-2 items-center">
          <i class="icon-{{t (concat 'marketing.includes.items.' item '.icon')}}"></i>
          <div class="flex w-full md:justify-start justify-between items-center md:text-nowrap">
            <span>{{t (concat 'marketing.includes.items.' item '.title')}}</span>
            <div class="flex md:ml-2">
              <Global::Tooltip @dataTestingIdOnClick="marketing-{{item}}-tooltip" @content={{t (concat 'marketing.includes.items.' item '.tooltip')}}
                               @icon="icon-info-circle"
                               @iconStyle="text-sm"
                               @direction="left-down"
                               @iconFontSize="text-sm" />
            </div>
          </div>
        </div>
      {{/each}}
    </div>
  </div>
  
  */
  {
    "id": "zcvI+jK1",
    "block": "[[[10,0],[14,0,\"flex flex-col mx-auto\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"font-bold text-left mb-4\"],[12],[1,[28,[35,0],[\"marketing.includes.title\"],null]],[13],[1,\"\\n  \"],[10,0],[14,0,\"grid grid-cols-1 md:grid-cols-2 gap-2 gap-x-4\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"items\"]]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"flex gap-2 mb-2 items-center\"],[12],[1,\"\\n        \"],[10,\"i\"],[15,0,[29,[\"icon-\",[28,[37,0],[[28,[37,3],[\"marketing.includes.items.\",[30,1],\".icon\"],null]],null]]]],[12],[13],[1,\"\\n        \"],[10,0],[14,0,\"flex w-full md:justify-start justify-between items-center md:text-nowrap\"],[12],[1,\"\\n          \"],[10,1],[12],[1,[28,[35,0],[[28,[37,3],[\"marketing.includes.items.\",[30,1],\".title\"],null]],null]],[13],[1,\"\\n          \"],[10,0],[14,0,\"flex md:ml-2\"],[12],[1,\"\\n            \"],[8,[39,4],null,[[\"@dataTestingIdOnClick\",\"@content\",\"@icon\",\"@iconStyle\",\"@direction\",\"@iconFontSize\"],[[29,[\"marketing-\",[30,1],\"-tooltip\"]],[28,[37,0],[[28,[37,3],[\"marketing.includes.items.\",[30,1],\".tooltip\"],null]],null],\"icon-info-circle\",\"text-sm\",\"left-down\",\"text-sm\"]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"item\"],false,[\"t\",\"each\",\"-track-array\",\"concat\",\"global/tooltip\"]]",
    "moduleName": "shared/components/marketing/includes.hbs",
    "isStrictMode": false
  });
  let MarketingIncludesComponent = _exports.default = (_class = class MarketingIncludesComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _defineProperty(this, "items", ['facebook', 'campaign', 'ads', 'manager', 'targeting', 'reporting']);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MarketingIncludesComponent);
});