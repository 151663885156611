define("shared/components/checkout/trust-box", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    id={{this.elementId}}
    class="trustpilot-widget"
    data-locale="en-US"
    data-template-id={{this.templateId}}
    data-businessunit-id={{this.businessUnitId}}
    style="width: 100%;"
    data-style-height="20px"
    data-style-width="100%"
    data-theme="light"
    {{did-insert this.setupTrustBox}}
    {{will-destroy this.teardownTrustBox}}
  >
    <a href="https://www.trustpilot.com/review/tailorbrands.com" target="_blank" rel="noopener noreferrer"><img src="/images/llc/trustpilot-rating-mobile.svg" alt={{t "trustpilot"}}></a>
  </div>
  
  */
  {
    "id": "GFml4xcE",
    "block": "[[[11,0],[16,1,[30,0,[\"elementId\"]]],[24,0,\"trustpilot-widget\"],[24,\"data-locale\",\"en-US\"],[16,\"data-template-id\",[30,0,[\"templateId\"]]],[16,\"data-businessunit-id\",[30,0,[\"businessUnitId\"]]],[24,5,\"width: 100%;\"],[24,\"data-style-height\",\"20px\"],[24,\"data-style-width\",\"100%\"],[24,\"data-theme\",\"light\"],[4,[38,0],[[30,0,[\"setupTrustBox\"]]],null],[4,[38,1],[[30,0,[\"teardownTrustBox\"]]],null],[12],[1,\"\\n  \"],[10,3],[14,6,\"https://www.trustpilot.com/review/tailorbrands.com\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[10,\"img\"],[14,\"src\",\"/images/llc/trustpilot-rating-mobile.svg\"],[15,\"alt\",[28,[37,2],[\"trustpilot\"],null]],[12],[13],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"did-insert\",\"will-destroy\",\"t\"]]",
    "moduleName": "shared/components/checkout/trust-box.hbs",
    "isStrictMode": false
  });
  let TrustBoxComponent = _exports.default = (_class = class TrustBoxComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "trustpilot", _descriptor, this);
      _defineProperty(this, "templateId", '5419b6ffb0d04a076446a9af');
      _defineProperty(this, "businessUnitId", '589985b40000ff00059c3bbf');
    }
    get elementId() {
      const id = `trustpilot-container-${Math.random().toString(36).substr(2, 9)}`;
      return id;
    }
    setupTrustBox(element) {
      this.trustpilot.loadTrustBox(element);
    }
    teardownTrustBox(element) {
      this.trustpilot.removeTrustBox(element);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "trustpilot", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupTrustBox", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setupTrustBox"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "teardownTrustBox", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "teardownTrustBox"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TrustBoxComponent);
});