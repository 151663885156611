define('@ember-data/record-data/-private', ['exports', '@ember/debug', '@ember/polyfills', '@ember/runloop', '@ember/utils', '@ember-data/store/-private'], (function (exports, debug, polyfills, runloop, utils, Private) { 'use strict';

  // Used by the store to normalize IDs entering the store.  Despite the fact
  // that developers may provide IDs as numbers (e.g., `store.findRecord('person', 1)`),
  // it is important that internally we use strings, since IDs may be serialized
  // and lose type information.  For example, Ember's router may put a record's
  // ID into the URL, and if we later try to deserialize that URL and find the
  // corresponding record, we will not know if it is a string or a number.

  function coerceId(id) {
    if (id === null || id === undefined || id === '') {
      return null;
    }
    if (typeof id === 'string') {
      return id;
    }
    if (typeof id === 'symbol') {
      return id.toString();
    }
    return '' + id;
  }

  function expandingGet(cache, key1, key2) {
    var mainCache = cache[key1] = cache[key1] || Object.create(null);
    return mainCache[key2];
  }
  function expandingSet(cache, key1, key2, value) {
    var mainCache = cache[key1] = cache[key1] || Object.create(null);
    mainCache[key2] = value;
  }
  function isNew(identifier) {
    if (!identifier.id) {
      return true;
    }
    var recordData = Private.recordDataFor(identifier);
    return recordData ? isRelationshipRecordData(recordData) && recordData.isNew() : false;
  }
  function isRelationshipRecordData(recordData) {
    return typeof recordData.isNew === 'function';
  }
  function isBelongsTo(relationship) {
    return relationship.definition.kind === 'belongsTo';
  }
  function isImplicit(relationship) {
    return relationship.definition.isImplicit;
  }
  function isHasMany(relationship) {
    return relationship.definition.kind === 'hasMany';
  }

  function createState() {
    return {
      hasReceivedData: false,
      isEmpty: true,
      isStale: false,
      hasFailedLoadAttempt: false,
      shouldForceReload: false,
      hasDematerializedInverse: false
    };
  }

  class BelongsToRelationship {
    constructor(graph, definition, identifier) {
      this.graph = graph;
      this.store = graph.store;
      this.definition = definition;
      this.identifier = identifier;
      this._state = null;
      this.transactionRef = 0;
      this.meta = null;
      this.links = null;
      this.localState = null;
      this.remoteState = null;
    }
    get state() {
      var {
        _state
      } = this;
      if (!_state) {
        _state = this._state = createState();
      }
      return _state;
    }
    recordDataDidDematerialize() {
      if (this.definition.inverseIsImplicit) {
        return;
      }
      var inverseKey = this.definition.inverseKey;
      var callback = inverseIdentifier => {
        if (!inverseIdentifier || !this.graph.has(inverseIdentifier, inverseKey)) {
          return;
        }
        var relationship = this.graph.get(inverseIdentifier, inverseKey);

        // For canonical members, it is possible that inverseRecordData has already been associated to
        // to another record. For such cases, do not dematerialize the inverseRecordData
        if (relationship.definition.kind !== 'belongsTo' || !relationship.localState || this.identifier === relationship.localState) {
          relationship.inverseDidDematerialize(this.identifier);
        }
      };
      if (this.remoteState) {
        callback(this.remoteState);
      }
      if (this.localState && this.localState !== this.remoteState) {
        callback(this.localState);
      }
    }
    inverseDidDematerialize() {
      var inverseRecordData = this.localState;
      if (!this.definition.isAsync || inverseRecordData && isNew(inverseRecordData)) {
        // unloading inverse of a sync relationship is treated as a client-side
        // delete, so actually remove the models don't merely invalidate the cp
        // cache.
        // if the record being unloaded only exists on the client, we similarly
        // treat it as a client side delete
        if (this.localState === inverseRecordData && inverseRecordData !== null) {
          this.localState = null;
        }
        if (this.remoteState === inverseRecordData && inverseRecordData !== null) {
          this.remoteState = null;
          this.state.hasReceivedData = true;
          this.state.isEmpty = true;
          if (this.localState && !isNew(this.localState)) {
            this.localState = null;
          }
        }
      } else {
        this.state.hasDematerializedInverse = true;
      }
      this.notifyBelongsToChange();
    }
    getData() {
      var data;
      var payload = {};
      if (this.localState) {
        data = this.localState;
      }
      if (this.localState === null && this.state.hasReceivedData) {
        data = null;
      }
      if (this.links) {
        payload.links = this.links;
      }
      if (data !== undefined) {
        payload.data = data;
      }
      if (this.meta) {
        payload.meta = this.meta;
      }
      payload._relationship = this;
      return payload;
    }

    /*
        Removes the given RecordData from BOTH canonical AND current state.
    
        This method is useful when either a deletion or a rollback on a new record
        needs to entirely purge itself from an inverse relationship.
       */
    removeCompletelyFromOwn(recordData) {
      if (this.remoteState === recordData) {
        this.remoteState = null;
      }
      if (this.localState === recordData) {
        this.localState = null;
        // This allows dematerialized inverses to be rematerialized
        // we shouldn't be notifying here though, figure out where
        // a notification was missed elsewhere.
        this.notifyBelongsToChange();
      }
    }
    notifyBelongsToChange() {
      var recordData = this.identifier;
      this.store.notifyBelongsToChange(recordData.type, recordData.id, recordData.lid, this.definition.key);
    }
    clear() {
      this.localState = null;
      this.remoteState = null;
      this.state.hasReceivedData = false;
      this.state.isEmpty = true;
    }
  }

  class ManyRelationship {
    constructor(graph, definition, identifier) {
      this.graph = graph;
      this.store = graph.store;
      this.definition = definition;
      this.identifier = identifier;
      this._state = null;
      this.transactionRef = 0;
      this.members = new Set();
      this.canonicalMembers = new Set();
      this.meta = null;
      this.links = null;

      // persisted state
      this.canonicalState = [];
      // local client state
      this.currentState = [];
      this._willUpdateManyArray = false;
      this._pendingManyArrayUpdates = null;
    }
    get state() {
      var {
        _state
      } = this;
      if (!_state) {
        _state = this._state = createState();
      }
      return _state;
    }
    recordDataDidDematerialize() {
      if (this.definition.inverseIsImplicit) {
        return;
      }
      var inverseKey = this.definition.inverseKey;
      this.forAllMembers(inverseIdentifier => {
        if (!inverseIdentifier || !this.graph.has(inverseIdentifier, inverseKey)) {
          return;
        }
        var relationship = this.graph.get(inverseIdentifier, inverseKey);
        // to another record. For such cases, do not dematerialize the inverseRecordData
        if (relationship.definition.kind !== 'belongsTo' || !relationship.localState || this.identifier === relationship.localState) {
          relationship.inverseDidDematerialize(this.identifier);
        }
      });
    }
    forAllMembers(callback) {
      // ensure we don't walk anything twice if an entry is
      // in both members and canonicalMembers
      var seen = Object.create(null);
      for (var i = 0; i < this.currentState.length; i++) {
        var inverseInternalModel = this.currentState[i];
        var id = inverseInternalModel.lid;
        if (!seen[id]) {
          seen[id] = true;
          callback(inverseInternalModel);
        }
      }
      for (var _i = 0; _i < this.canonicalState.length; _i++) {
        var _inverseInternalModel = this.canonicalState[_i];
        var _id = _inverseInternalModel.lid;
        if (!seen[_id]) {
          seen[_id] = true;
          callback(_inverseInternalModel);
        }
      }
    }
    clear() {
      this.members.clear();
      this.canonicalMembers.clear();
      this.currentState = [];
      this.canonicalState = [];
    }
    inverseDidDematerialize(inverseRecordData) {
      if (!this.definition.isAsync || inverseRecordData && isNew(inverseRecordData)) {
        // unloading inverse of a sync relationship is treated as a client-side
        // delete, so actually remove the models don't merely invalidate the cp
        // cache.
        // if the record being unloaded only exists on the client, we similarly
        // treat it as a client side delete
        this.removeCompletelyFromOwn(inverseRecordData);
      } else {
        this.state.hasDematerializedInverse = true;
      }
      this.notifyHasManyChange();
    }

    /*
      Removes the given RecordData from BOTH canonical AND current state.
       This method is useful when either a deletion or a rollback on a new record
      needs to entirely purge itself from an inverse relationship.
    */
    removeCompletelyFromOwn(recordData) {
      this.canonicalMembers.delete(recordData);
      this.members.delete(recordData);
      var canonicalIndex = this.canonicalState.indexOf(recordData);
      if (canonicalIndex !== -1) {
        this.canonicalState.splice(canonicalIndex, 1);
      }
      var currentIndex = this.currentState.indexOf(recordData);
      if (currentIndex !== -1) {
        this.currentState.splice(currentIndex, 1);
        // This allows dematerialized inverses to be rematerialized
        // we shouldn't be notifying here though, figure out where
        // a notification was missed elsewhere.
        this.notifyHasManyChange();
      }
    }
    notifyHasManyChange() {
      var {
        store,
        identifier: recordData
      } = this;
      store.notifyHasManyChange(recordData.type, recordData.id, recordData.lid, this.definition.key);
    }
    getData() {
      var payload = {};
      if (this.state.hasReceivedData) {
        payload.data = this.currentState.slice();
      }
      if (this.links) {
        payload.links = this.links;
      }
      if (this.meta) {
        payload.meta = this.meta;
      }
      return payload;
    }
  }

  /**
    @module @ember-data/store
  */
  class ImplicitRelationship {
    constructor(graph, definition, identifier) {
      this.graph = graph;
      this.definition = definition;
      this.identifier = identifier;
      this.members = new Set();
      this.canonicalMembers = new Set();
    }
    addCanonicalRecordData(recordData, idx) {
      if (!this.canonicalMembers.has(recordData)) {
        this.canonicalMembers.add(recordData);
        this.members.add(recordData);
      }
    }
    addRecordData(recordData, idx) {
      if (!this.members.has(recordData)) {
        this.members.add(recordData);
      }
    }
    removeRecordData(recordData) {
      if (recordData && this.members.has(recordData)) {
        this.members.delete(recordData);
      }
    }
    removeCompletelyFromOwn(recordData) {
      this.canonicalMembers.delete(recordData);
      this.members.delete(recordData);
    }
    clear() {
      this.canonicalMembers.clear();
      this.members.clear();
    }
  }

  var BOOL_LATER = null;
  var STR_LATER = '';
  var IMPLICIT_KEY_RAND = Date.now();
  function implicitKeyFor(type, key) {
    return `implicit-${type}:${key}${IMPLICIT_KEY_RAND}`;
  }
  function syncMeta(definition, inverseDefinition) {
    definition.inverseKind = inverseDefinition.kind;
    definition.inverseKey = inverseDefinition.key;
    definition.inverseType = inverseDefinition.type;
    definition.inverseIsAsync = inverseDefinition.isAsync;
    definition.inverseIsCollection = inverseDefinition.isCollection;
    definition.inverseIsPolymorphic = inverseDefinition.isPolymorphic;
    definition.inverseIsImplicit = inverseDefinition.isImplicit;
  }
  function upgradeMeta(meta) {
    var niceMeta = {};
    var options = meta.options;
    niceMeta.kind = meta.kind;
    niceMeta.key = meta.name;
    niceMeta.type = meta.type;
    niceMeta.isAsync = options && options.async !== undefined ? !!options.async : true;
    niceMeta.isImplicit = false;
    niceMeta.isCollection = meta.kind === 'hasMany';
    niceMeta.isPolymorphic = options && !!options.polymorphic;
    niceMeta.inverseKey = options && options.inverse;
    niceMeta.inverseType = STR_LATER;
    niceMeta.inverseIsAsync = BOOL_LATER;
    niceMeta.inverseIsImplicit = options && options.inverse === null || BOOL_LATER;
    niceMeta.inverseIsCollection = BOOL_LATER;
    return niceMeta;
  }
  function isLHS(info, type, key) {
    var isSelfReferential = info.isSelfReferential;
    var isRelationship = key === info.lhs_relationshipName;
    if (isRelationship === true) {
      return isSelfReferential === true ||
      // itself
      type === info.lhs_baseModelName ||
      // base or non-polymorphic
      // if the other side is polymorphic then we need to scan our modelNames
      info.rhs_isPolymorphic && info.lhs_modelNames.indexOf(type) !== -1 // polymorphic
      ;
    }
    return false;
  }
  function upgradeDefinition(graph, identifier, propertyName, isImplicit = false) {
    var cache = graph._definitionCache;
    var storeWrapper = graph.store;
    var polymorphicLookup = graph._potentialPolymorphicTypes;
    var {
      type
    } = identifier;
    var cached = expandingGet(cache, type, propertyName);

    // CASE: We have a cached resolution (null if no relationship exists)
    if (cached !== undefined) {
      return cached;
    }
    var relationships = storeWrapper.relationshipsDefinitionFor(type);
    var meta = relationships[propertyName];
    if (!meta) {
      if (polymorphicLookup[type]) {
        var altTypes = Object.keys(polymorphicLookup[type]);
        for (var i = 0; i < altTypes.length; i++) {
          var _cached = expandingGet(cache, altTypes[i], propertyName);
          if (_cached) {
            expandingSet(cache, type, propertyName, _cached);
            return _cached;
          }
        }
      }
      cache[type][propertyName] = null;
      return null;
    }
    var definition = upgradeMeta(meta);
    var inverseDefinition;
    var inverseKey;
    var inverseType = definition.type;

    // CASE: Inverse is explicitly null
    if (definition.inverseKey === null) {
      inverseDefinition = null;
    } else {
      inverseKey = storeWrapper.inverseForRelationship(type, propertyName);

      // CASE: Inverse resolves to null
      if (!inverseKey) {
        inverseDefinition = null;
      } else {
        // CASE: We have an explicit inverse or were able to resolve one
        var inverseDefinitions = storeWrapper.relationshipsDefinitionFor(inverseType);
        var _meta = inverseDefinitions[inverseKey];
        inverseDefinition = upgradeMeta(_meta);
      }
    }

    // CASE: We have no inverse
    if (!inverseDefinition) {
      // polish off meta
      inverseKey = implicitKeyFor(type, propertyName);
      inverseDefinition = {
        kind: 'implicit',
        key: inverseKey,
        type: type,
        isAsync: false,
        isImplicit: true,
        isCollection: true,
        // with implicits any number of records could point at us
        isPolymorphic: false
      };
      syncMeta(definition, inverseDefinition);
      syncMeta(inverseDefinition, definition);
      var _info = {
        lhs_key: `${type}:${propertyName}`,
        lhs_modelNames: [type],
        lhs_baseModelName: type,
        lhs_relationshipName: propertyName,
        lhs_definition: definition,
        lhs_isPolymorphic: definition.isPolymorphic,
        rhs_key: '',
        rhs_modelNames: [],
        rhs_baseModelName: inverseType,
        rhs_relationshipName: '',
        rhs_definition: inverseDefinition,
        rhs_isPolymorphic: false,
        hasInverse: false,
        isSelfReferential: type === inverseType,
        // this could be wrong if we are self-referential but also polymorphic
        isReflexive: false // we can't be reflexive if we don't define an inverse
      };
      expandingSet(cache, inverseType, inverseKey, _info);
      expandingSet(cache, type, propertyName, _info);
      return _info;
    }

    // CASE: We do have an inverse
    var baseType = inverseDefinition.type;

    // TODO we want to assert this but this breaks all of our shoddily written tests
    /*
      if (DEBUG) {
        let inverseDoubleCheck = inverseMeta.type.inverseFor(inverseRelationshipName, store);
         assert(`The ${inverseBaseModelName}:${inverseRelationshipName} relationship declares 'inverse: null', but it was resolved as the inverse for ${baseModelName}:${relationshipName}.`, inverseDoubleCheck);
      }
    */
    // CASE: We may have already discovered the inverse for the baseModelName
    // CASE: We have already discovered the inverse
    cached = expandingGet(cache, baseType, propertyName) || expandingGet(cache, inverseType, inverseKey);
    if (cached) {
      var _isLHS = cached.lhs_baseModelName === baseType;
      var modelNames = _isLHS ? cached.lhs_modelNames : cached.rhs_modelNames;
      // make this lookup easier in the future by caching the key
      modelNames.push(type);
      expandingSet(cache, type, propertyName, cached);
      return cached;
    }

    // this is our first time so polish off the metas
    syncMeta(definition, inverseDefinition);
    syncMeta(inverseDefinition, definition);
    var lhs_modelNames = [type];
    if (type !== baseType) {
      lhs_modelNames.push(baseType);
    }
    var isSelfReferential = type === inverseType;
    var info = {
      lhs_key: `${baseType}:${propertyName}`,
      lhs_modelNames,
      lhs_baseModelName: baseType,
      lhs_relationshipName: propertyName,
      lhs_definition: definition,
      lhs_isPolymorphic: definition.isPolymorphic,
      rhs_key: `${inverseType}:${inverseKey}`,
      rhs_modelNames: [inverseType],
      rhs_baseModelName: inverseType,
      rhs_relationshipName: inverseKey,
      rhs_definition: inverseDefinition,
      rhs_isPolymorphic: inverseDefinition.isPolymorphic,
      hasInverse: true,
      isSelfReferential,
      isReflexive: isSelfReferential && propertyName === inverseKey
    };

    // Create entries for the baseModelName as well as modelName to speed up
    //  inverse lookups
    expandingSet(cache, baseType, propertyName, info);
    expandingSet(cache, type, propertyName, info);

    // Greedily populate the inverse
    expandingSet(cache, inverseType, inverseKey, info);
    return info;
  }

  /*
      case many:1
      ========
      In a bi-directional graph with Many:1 edges, adding a value
      results in up-to 3 discrete value transitions, while removing
      a value is only 2 transitions.

      For adding C to A
      If: A <<-> B, C <->> D is the initial state,
      and: B <->> A <<-> C, D is the final state

      then we would undergo the following transitions.

      add C to A
      remove C from D
      add A to C

      For removing B from A
      If: A <<-> B, C <->> D is the initial state,
      and: A, B, C <->> D is the final state

      then we would undergo the following transitions.

      remove B from A
      remove A from B

      case many:many
      ===========
      In a bi-directional graph with Many:Many edges, adding or
      removing a value requires only 2 value transitions.

      For Adding
      If: A<<->>B, C<<->>D is the initial state (double arrows representing the many side)
      And: D<<->>C<<->>A<<->>B is the final state

      Then we would undergo two transitions.

      add C to A.
      add A to C

      For Removing
      If: A<<->>B, C<<->>D is the initial state (double arrows representing the many side)
      And: A, B, C<<->>D is the final state

      Then we would undergo two transitions.

      remove B from A
      remove A from B

      case many:?
      ========
      In a uni-directional graph with Many:? edges (modeled in EmberData with `inverse:null`) with
      artificial (implicit) inverses, replacing a value results in 2 discrete value transitions.
      This is because a Many:? relationship is effectively Many:Many.
    */
  function replaceRelatedRecords(graph, op, isRemote) {
    if (isRemote) {
      replaceRelatedRecordsRemote(graph, op, isRemote);
    } else {
      replaceRelatedRecordsLocal(graph, op, isRemote);
    }
  }
  function replaceRelatedRecordsLocal(graph, op, isRemote) {
    var identifiers = op.value;
    var identifiersLength = identifiers.length;
    var relationship = graph.get(op.record, op.field);
    relationship.state.hasReceivedData = true;
    var newValues = Object.create(null);
    for (var i = 0; i < identifiersLength; i++) {
      newValues[identifiers[i].lid] = true;
    }

    // cache existing state
    var {
      currentState,
      members,
      definition
    } = relationship;
    var newState = new Array(identifiers.length);
    var newMembership = new Set();

    // wipe existing state
    relationship.members = newMembership;
    relationship.currentState = newState;
    var {
      type
    } = relationship.definition;
    var changed = false;
    var currentLength = currentState.length;
    var iterationLength = currentLength > identifiersLength ? currentLength : identifiersLength;
    var equalLength = currentLength === identifiersLength;
    for (var _i = 0; _i < iterationLength; _i++) {
      if (_i < identifiersLength) {
        var identifier = identifiers[_i];
        if (type !== identifier.type) {
          graph.registerPolymorphicType(type, identifier.type);
        }
        newState[_i] = identifier;
        newMembership.add(identifier);
        if (!members.has(identifier)) {
          changed = true;
          addToInverse(graph, identifier, definition.inverseKey, op.record, isRemote);
        }
      }
      if (_i < currentLength) {
        var _identifier = currentState[_i];

        // detect reordering
        if (equalLength && newState[_i] !== _identifier) {
          changed = true;
        }
        if (!newValues[_identifier.lid]) {
          changed = true;
          removeFromInverse(graph, _identifier, definition.inverseKey, op.record, isRemote);
        }
      }
    }
    if (changed) {
      relationship.notifyHasManyChange();
    }
  }
  function replaceRelatedRecordsRemote(graph, op, isRemote) {
    var identifiers = op.value;
    var identifiersLength = identifiers.length;
    var relationship = graph.get(op.record, op.field);
    if (isRemote) {
      graph._addToTransaction(relationship);
    }
    relationship.state.hasReceivedData = true;
    var newValues = Object.create(null);
    for (var i = 0; i < identifiersLength; i++) {
      newValues[identifiers[i].lid] = true;
    }

    // cache existing state
    var {
      canonicalState,
      canonicalMembers,
      definition
    } = relationship;
    var newState = new Array(identifiers.length);
    var newMembership = new Set();

    // wipe existing state
    relationship.canonicalMembers = newMembership;
    relationship.canonicalState = newState;
    var {
      type
    } = relationship.definition;
    var changed = false;
    var canonicalLength = canonicalState.length;
    var iterationLength = canonicalLength > identifiersLength ? canonicalLength : identifiersLength;
    var equalLength = canonicalLength === identifiersLength;
    for (var _i2 = 0; _i2 < iterationLength; _i2++) {
      if (_i2 < identifiersLength) {
        var identifier = identifiers[_i2];
        if (type !== identifier.type) {
          graph.registerPolymorphicType(type, identifier.type);
        }
        newState[_i2] = identifier;
        newMembership.add(identifier);
        if (!canonicalMembers.has(identifier)) {
          changed = true;
          addToInverse(graph, identifier, definition.inverseKey, op.record, isRemote);
        }
      }
      if (_i2 < canonicalLength) {
        var _identifier2 = canonicalState[_i2];

        // detect reordering
        if (equalLength && newState[_i2] !== _identifier2) {
          changed = true;
        }
        if (!newValues[_identifier2.lid]) {
          changed = true;
          removeFromInverse(graph, _identifier2, definition.inverseKey, op.record, isRemote);
        }
      }
    }
    if (changed) {
      flushCanonical(graph, relationship);
      /*
      replaceRelatedRecordsLocal(
        graph,
        {
          op: op.op,
          record: op.record,
          field: op.field,
          value: canonicalState,
        },
        false
      );*/
    } else {
      // preserve legacy behavior we want to change but requires some sort
      // of deprecation.
      flushCanonical(graph, relationship);
    }
  }
  function addToInverse(graph, identifier, key, value, isRemote) {
    var relationship = graph.get(identifier, key);
    var {
      type
    } = relationship.definition;
    if (type !== value.type) {
      graph.registerPolymorphicType(type, value.type);
    }
    if (isBelongsTo(relationship)) {
      relationship.state.hasReceivedData = true;
      relationship.state.isEmpty = false;
      if (isRemote) {
        graph._addToTransaction(relationship);
        if (relationship.remoteState !== null) {
          removeFromInverse(graph, relationship.remoteState, relationship.definition.inverseKey, identifier, isRemote);
        }
        relationship.remoteState = value;
      }
      if (relationship.localState !== value) {
        if (!isRemote && relationship.localState) {
          removeFromInverse(graph, relationship.localState, relationship.definition.inverseKey, identifier, isRemote);
        }
        relationship.localState = value;
        relationship.notifyBelongsToChange();
      }
    } else if (isHasMany(relationship)) {
      if (isRemote) {
        if (!relationship.canonicalMembers.has(value)) {
          graph._addToTransaction(relationship);
          relationship.canonicalState.push(value);
          relationship.canonicalMembers.add(value);
          relationship.state.hasReceivedData = true;
          flushCanonical(graph, relationship);
        }
      } else {
        if (!relationship.members.has(value)) {
          relationship.currentState.push(value);
          relationship.members.add(value);
          relationship.state.hasReceivedData = true;
          relationship.notifyHasManyChange();
        }
      }
    } else {
      if (isRemote) {
        relationship.addCanonicalRecordData(value);
      } else {
        relationship.addRecordData(value);
      }
    }
  }
  function removeFromInverse(graph, identifier, key, value, isRemote) {
    var relationship = graph.get(identifier, key);
    if (isBelongsTo(relationship)) {
      relationship.state.isEmpty = true;
      if (isRemote) {
        graph._addToTransaction(relationship);
        relationship.remoteState = null;
      }
      if (relationship.localState === value) {
        relationship.localState = null;
        relationship.notifyBelongsToChange();
      }
    } else if (isHasMany(relationship)) {
      if (isRemote) {
        graph._addToTransaction(relationship);
        var _index = relationship.canonicalState.indexOf(value);
        if (_index !== -1) {
          relationship.canonicalMembers.delete(value);
          relationship.canonicalState.splice(_index, 1);
        }
      }
      var index = relationship.currentState.indexOf(value);
      if (index !== -1) {
        relationship.members.delete(value);
        relationship.currentState.splice(index, 1);
      }
      relationship.notifyHasManyChange();
    } else {
      if (isRemote) {
        relationship.removeCompletelyFromOwn(value);
      } else {
        relationship.removeRecordData(value);
      }
    }
  }
  function syncRemoteToLocal(rel) {
    var toSet = rel.canonicalState;
    var newRecordDatas = rel.currentState.filter(recordData => isNew(recordData) && toSet.indexOf(recordData) === -1);
    var existingState = rel.currentState;
    rel.currentState = toSet.concat(newRecordDatas);
    var members = rel.members = new Set();
    rel.canonicalMembers.forEach(v => members.add(v));
    for (var i = 0; i < newRecordDatas.length; i++) {
      members.add(newRecordDatas[i]);
    }

    // TODO always notifying fails only one test and we should probably do away with it
    if (existingState.length !== rel.currentState.length) {
      rel.notifyHasManyChange();
    } else {
      for (var _i3 = 0; _i3 < existingState.length; _i3++) {
        if (existingState[_i3] !== rel.currentState[_i3]) {
          rel.notifyHasManyChange();
          break;
        }
      }
    }
  }
  function flushCanonical(graph, rel) {
    graph._scheduleLocalSync(rel);
  }

  function addToRelatedRecords(graph, op, isRemote) {
    var {
      record,
      value,
      index
    } = op;
    var relationship = graph.get(record, op.field);
    if (Array.isArray(value)) {
      for (var i = 0; i < value.length; i++) {
        addRelatedRecord(graph, relationship, record, value[i], index !== undefined ? index + i : index, isRemote);
      }
    } else {
      addRelatedRecord(graph, relationship, record, value, index, isRemote);
    }
    relationship.notifyHasManyChange();
  }
  function addRelatedRecord(graph, relationship, record, value, index, isRemote) {
    var {
      members,
      currentState
    } = relationship;
    if (members.has(value)) {
      return;
    }
    var {
      type
    } = relationship.definition;
    if (type !== value.type) {
      graph.registerPolymorphicType(value.type, type);
    }
    relationship.state.hasReceivedData = true;
    members.add(value);
    if (index === undefined) {
      currentState.push(value);
    } else {
      currentState.splice(index, 0, value);
    }
    addToInverse(graph, value, relationship.definition.inverseKey, record, isRemote);
  }

  function removeFromRelatedRecords(graph, op, isRemote) {
    var {
      record,
      value
    } = op;
    var relationship = graph.get(record, op.field);
    if (Array.isArray(value)) {
      for (var i = 0; i < value.length; i++) {
        removeRelatedRecord(graph, relationship, record, value[i], isRemote);
      }
    } else {
      removeRelatedRecord(graph, relationship, record, value, isRemote);
    }
    relationship.notifyHasManyChange();
  }
  function removeRelatedRecord(graph, relationship, record, value, isRemote) {
    var {
      members,
      currentState
    } = relationship;
    if (!members.has(value)) {
      return;
    }
    members.delete(value);
    var index = currentState.indexOf(value);
    currentState.splice(index, 1);
    removeFromInverse(graph, value, relationship.definition.inverseKey, record, isRemote);
  }

  function replaceRelatedRecord(graph, op, isRemote = false) {
    var relationship = graph.get(op.record, op.field);
    if (isRemote) {
      graph._addToTransaction(relationship);
    }
    var {
      definition,
      state
    } = relationship;
    var prop = isRemote ? 'remoteState' : 'localState';
    var existingState = relationship[prop];

    /*
      case 1:1
      ========
      In a bi-directional graph with 1:1 edges, replacing a value
      results in up-to 4 discrete value transitions.
       If: A <-> B, C <-> D is the initial state,
      and: A <-> C, B, D is the final state
       then we would undergo the following 4 transitions.
       remove A from B
      add C to A
      remove C from D
      add A to C
       case 1:many
      ===========
      In a bi-directional graph with 1:Many edges, replacing a value
      results in up-to 3 discrete value transitions.
       If: A<->>B<<->D, C<<->D is the initial state (double arrows representing the many side)
      And: A<->>C<<->D, B<<->D is the final state
       Then we would undergo three transitions.
       remove A from B
      add C to A.
      add A to C
       case 1:?
      ========
      In a uni-directional graph with 1:? edges (modeled in EmberData with `inverse:null`) with
      artificial (implicit) inverses, replacing a value results in up-to 3 discrete value transitions.
      This is because a 1:? relationship is effectively 1:many.
       If: A->B, C->B is the initial state
      And: A->C, C->B is the final state
       Then we would undergo three transitions.
       Remove A from B
      Add C to A
      Add A to C
    */

    // nothing for us to do
    if (op.value === existingState) {
      // if we were empty before but now know we are empty this needs to be true
      state.hasReceivedData = true;
      // if this is a remote update we still sync
      if (isRemote) {
        var {
          localState
        } = relationship;
        // don't sync if localState is a new record and our canonicalState is null
        if (localState && isNew(localState) && !existingState || localState === existingState) {
          return;
        }
        relationship.localState = existingState;
        relationship.notifyBelongsToChange();
      }
      return;
    }

    // remove this value from the inverse if required
    if (existingState) {
      removeFromInverse(graph, existingState, definition.inverseKey, op.record, isRemote);
    }

    // update value to the new value
    relationship[prop] = op.value;
    state.hasReceivedData = true;
    state.isEmpty = op.value === null;
    state.isStale = false;
    state.hasFailedLoadAttempt = false;
    if (op.value) {
      if (definition.type !== op.value.type) {
        graph.registerPolymorphicType(definition.type, op.value.type);
      }
      addToInverse(graph, op.value, definition.inverseKey, op.record, isRemote);
    }
    if (isRemote) {
      var {
        localState: _localState,
        remoteState
      } = relationship;
      if (_localState && isNew(_localState) && !remoteState) {
        return;
      }
      if (_localState !== remoteState) {
        relationship.localState = remoteState;
        relationship.notifyBelongsToChange();
      }
    } else {
      relationship.notifyBelongsToChange();
    }
  }

  /*
    This method normalizes a link to an "links object". If the passed link is
    already an object it's returned without any modifications.

    See http://jsonapi.org/format/#document-links for more information.
  */
  function _normalizeLink(link) {
    switch (typeof link) {
      case 'object':
        return link;
      case 'string':
        return {
          href: link
        };
    }
  }

  /*
      Updates the "canonical" or "remote" state of a relationship, replacing any existing
      state and blowing away any local changes (excepting new records).
  */
  function updateRelationshipOperation(graph, op) {
    var relationship = graph.get(op.record, op.field);
    var {
      definition,
      state,
      identifier
    } = relationship;
    var {
      isCollection
    } = definition;
    var payload = op.value;
    var hasRelationshipDataProperty = false;
    var hasUpdatedLink = false;
    if (payload.meta) {
      relationship.meta = payload.meta;
    }
    if (payload.data !== undefined) {
      hasRelationshipDataProperty = true;
      if (isCollection) {
        // TODO deprecate this case. We
        // have tests saying we support it.
        if (payload.data === null) {
          payload.data = [];
        }
        graph.update({
          op: 'replaceRelatedRecords',
          record: identifier,
          field: op.field,
          value: payload.data.map(i => graph.store.identifierCache.getOrCreateRecordIdentifier(i))
        }, true);
      } else {
        graph.update({
          op: 'replaceRelatedRecord',
          record: identifier,
          field: op.field,
          value: payload.data ? graph.store.identifierCache.getOrCreateRecordIdentifier(payload.data) : null
        }, true);
      }
    } else if (definition.isAsync === false && !state.hasReceivedData) {
      hasRelationshipDataProperty = true;
      if (isCollection) {
        graph.update({
          op: 'replaceRelatedRecords',
          record: identifier,
          field: op.field,
          value: []
        }, true);
      } else {
        graph.update({
          op: 'replaceRelatedRecord',
          record: identifier,
          field: op.field,
          value: null
        }, true);
      }
    }
    if (payload.links) {
      var originalLinks = relationship.links;
      relationship.links = payload.links;
      if (payload.links.related) {
        var relatedLink = _normalizeLink(payload.links.related);
        var currentLink = originalLinks && originalLinks.related ? _normalizeLink(originalLinks.related) : null;
        var currentLinkHref = currentLink ? currentLink.href : null;
        if (relatedLink && relatedLink.href && relatedLink.href !== currentLinkHref) {
          hasUpdatedLink = true;
        }
      }
    }

    /*
         Data being pushed into the relationship might contain only data or links,
         or a combination of both.
    
         IF contains only data
         IF contains both links and data
          state.isEmpty -> true if is empty array (has-many) or is null (belongs-to)
          state.hasReceivedData -> true
          hasDematerializedInverse -> false
          state.isStale -> false
          allInverseRecordsAreLoaded -> run-check-to-determine
    
         IF contains only links
          state.isStale -> true
         */
    relationship.state.hasFailedLoadAttempt = false;
    if (hasRelationshipDataProperty) {
      var relationshipIsEmpty = payload.data === null || Array.isArray(payload.data) && payload.data.length === 0;

      // we don't need to notify here as the update op we pushed in above will notify once
      // membership is in the correct state.
      relationship.state.hasReceivedData = true;
      relationship.state.isStale = false;
      relationship.state.hasDematerializedInverse = false;
      relationship.state.isEmpty = relationshipIsEmpty;
    } else if (hasUpdatedLink) {
      // only notify stale if we have not previously received membership data.
      // within this same transaction
      // this prevents refetching when only one side of the relationship in the
      // payload contains the info while the other side contains just a link
      // this only works when the side with just a link is a belongsTo, as we
      // don't know if a hasMany has full information or not.
      // see #7049 for context.
      if (isCollection || !relationship.state.hasReceivedData || relationship.transactionRef === 0) {
        relationship.state.isStale = true;
        if (isHasMany(relationship)) {
          relationship.notifyHasManyChange();
        } else {
          relationship.notifyBelongsToChange();
        }
      } else {
        relationship.state.isStale = false;
      }
    }
  }

  var Graphs = new WeakMap();
  function isStore(maybeStore) {
    return maybeStore._storeWrapper !== undefined;
  }
  function getWrapper(store) {
    return isStore(store) ? store._storeWrapper : store;
  }
  function peekGraph(store) {
    return Graphs.get(getWrapper(store));
  }
  function graphFor(store) {
    var wrapper = getWrapper(store);
    var graph = Graphs.get(wrapper);
    if (graph === undefined) {
      graph = new Graph(wrapper);
      Graphs.set(wrapper, graph);
    }
    return graph;
  }

  /*
   * Graph acts as the cache for relationship data. It allows for
   * us to ask about and update relationships for a given Identifier
   * without requiring other objects for that Identifier to be
   * instantiated (such as `InternalModel`, `RecordData` or a `Record`)
   *
   * This also allows for us to make more substantive changes to relationships
   * with increasingly minor alterations to other portions of the internals
   * over time.
   *
   * The graph is made up of nodes and edges. Each unique identifier gets
   * its own node, which is a dictionary with a list of that node's edges
   * (or connections) to other nodes. In `Model` terms, a node represents a
   * record instance, with each key (an edge) in the dictionary correlating
   * to either a `hasMany` or `belongsTo` field on that record instance.
   *
   * The value for each key, or `edge` is the identifier(s) the node relates
   * to in the graph from that key.
   */
  class Graph {
    constructor(store) {
      this._definitionCache = Object.create(null);
      this._potentialPolymorphicTypes = Object.create(null);
      this.identifiers = new Map();
      this.store = store;
      this._willSyncRemote = false;
      this._willSyncLocal = false;
      this._pushedUpdates = {
        belongsTo: [],
        hasMany: [],
        deletions: []
      };
      this._updatedRelationships = new Set();
      this._transaction = null;
    }
    has(identifier, propertyName) {
      var relationships = this.identifiers.get(identifier);
      if (!relationships) {
        return false;
      }
      return relationships[propertyName] !== undefined;
    }
    get(identifier, propertyName) {
      var relationships = this.identifiers.get(identifier);
      if (!relationships) {
        relationships = Object.create(null);
        this.identifiers.set(identifier, relationships);
      }
      var relationship = relationships[propertyName];
      if (!relationship) {
        var info = upgradeDefinition(this, identifier, propertyName);
        var meta = isLHS(info, identifier.type, propertyName) ? info.lhs_definition : info.rhs_definition;
        var Klass = meta.kind === 'hasMany' ? ManyRelationship : meta.kind === 'belongsTo' ? BelongsToRelationship : ImplicitRelationship;
        relationship = relationships[propertyName] = new Klass(this, meta, identifier);
      }
      return relationship;
    }

    /*
     * Allows for the graph to dynamically discover polymorphic connections
     * without needing to walk prototype chains.
     *
     * Used by edges when an added `type` does not match the expected `type`
     * for that edge.
     *
     * Currently we assert before calling this. For a public API we will want
     * to call out to the schema manager to ask if we should consider these
     * types as equivalent for a given relationship.
     */
    registerPolymorphicType(type1, type2) {
      var typeCache = this._potentialPolymorphicTypes;
      var t1 = typeCache[type1];
      if (!t1) {
        t1 = typeCache[type1] = Object.create(null);
      }
      t1[type2] = true;
      var t2 = typeCache[type2];
      if (!t2) {
        t2 = typeCache[type2] = Object.create(null);
      }
      t2[type1] = true;
    }

    /*
     TODO move this comment somewhere else
     implicit relationships are relationships which have not been declared but the inverse side exists on
     another record somewhere
     
     For example if there was:
      ```app/models/comment.js
     import Model, { attr } from '@ember-data/model';
      export default class Comment extends Model {
       @attr text;
     }
     ```
      and there is also:
      ```app/models/post.js
     import Model, { attr, hasMany } from '@ember-data/model';
      export default class Post extends Model {
       @attr title;
       @hasMany('comment') comments;
     }
     ```
      Then we would have a implicit 'post' relationship for the comment record in order
     to be do things like remove the comment from the post if the comment were to be deleted.
    */

    isReleasable(identifier) {
      var relationships = this.identifiers.get(identifier);
      if (!relationships) {
        return true;
      }
      var keys = Object.keys(relationships);
      for (var i = 0; i < keys.length; i++) {
        var relationship = relationships[keys[i]];
        if (relationship.definition.inverseIsAsync) {
          return false;
        }
      }
      return true;
    }
    unload(identifier) {
      var relationships = this.identifiers.get(identifier);
      if (relationships) {
        // cleans up the graph but retains some nodes
        // to allow for rematerialization
        Object.keys(relationships).forEach(key => {
          var rel = relationships[key];
          destroyRelationship(rel);
          if (isImplicit(rel)) {
            delete relationships[key];
          }
        });
      }
    }
    remove(identifier) {
      this.unload(identifier);
      this.identifiers.delete(identifier);
    }

    /*
     * Remote state changes
     */
    push(op) {
      if (op.op === 'deleteRecord') {
        this._pushedUpdates.deletions.push(op);
      } else if (op.op === 'replaceRelatedRecord') {
        this._pushedUpdates.belongsTo.push(op);
      } else {
        var relationship = this.get(op.record, op.field);
        this._pushedUpdates[relationship.definition.kind].push(op);
      }
      if (!this._willSyncRemote) {
        this._willSyncRemote = true;
        var backburner = this.store._store._backburner;
        backburner.schedule('coalesce', this, this._flushRemoteQueue);
      }
    }

    /*
     * Local state changes
     */

    update(op, isRemote = false) {
      switch (op.op) {
        case 'updateRelationship':
          updateRelationshipOperation(this, op);
          break;
        case 'deleteRecord':
          {
            var identifier = op.record;
            var relationships = this.identifiers.get(identifier);
            if (relationships) {
              Object.keys(relationships).forEach(key => {
                var rel = relationships[key];
                // works together with the has check
                delete relationships[key];
                removeCompletelyFromInverse(rel);
              });
              this.identifiers.delete(identifier);
            }
            break;
          }
        case 'replaceRelatedRecord':
          replaceRelatedRecord(this, op, isRemote);
          break;
        case 'addToRelatedRecords':
          addToRelatedRecords(this, op, isRemote);
          break;
        case 'removeFromRelatedRecords':
          removeFromRelatedRecords(this, op, isRemote);
          break;
        case 'replaceRelatedRecords':
          replaceRelatedRecords(this, op, isRemote);
          break;
      }
    }
    _scheduleLocalSync(relationship) {
      this._updatedRelationships.add(relationship);
      if (!this._willSyncLocal) {
        this._willSyncLocal = true;
        var backburner = this.store._store._backburner;
        backburner.schedule('sync', this, this._flushLocalQueue);
      }
    }
    _flushRemoteQueue() {
      if (!this._willSyncRemote) {
        return;
      }
      this._transaction = new Set();
      this._willSyncRemote = false;
      var {
        deletions,
        hasMany,
        belongsTo
      } = this._pushedUpdates;
      this._pushedUpdates.deletions = [];
      this._pushedUpdates.hasMany = [];
      this._pushedUpdates.belongsTo = [];
      for (var i = 0; i < deletions.length; i++) {
        this.update(deletions[i], true);
      }
      for (var _i = 0; _i < hasMany.length; _i++) {
        this.update(hasMany[_i], true);
      }
      for (var _i2 = 0; _i2 < belongsTo.length; _i2++) {
        this.update(belongsTo[_i2], true);
      }
      this._finalize();
    }
    _addToTransaction(relationship) {
      relationship.transactionRef++;
      this._transaction.add(relationship);
    }
    _finalize() {
      if (this._transaction) {
        this._transaction.forEach(v => v.transactionRef = 0);
        this._transaction = null;
      }
    }
    _flushLocalQueue() {
      if (!this._willSyncLocal) {
        return;
      }
      this._willSyncLocal = false;
      var updated = this._updatedRelationships;
      this._updatedRelationships = new Set();
      updated.forEach(syncRemoteToLocal);
    }
    willDestroy() {
      this.identifiers.clear();
      this.store = null;
    }
    destroy() {
      Graphs.delete(this.store);
    }
  }

  // Handle dematerialization for relationship `rel`.  In all cases, notify the
  // relationship of the dematerialization: this is done so the relationship can
  // notify its inverse which needs to update state
  //
  // If the inverse is sync, unloading this record is treated as a client-side
  // delete, so we remove the inverse records from this relationship to
  // disconnect the graph.  Because it's not async, we don't need to keep around
  // the internalModel as an id-wrapper for references and because the graph is
  // disconnected we can actually destroy the internalModel when checking for
  // orphaned models.
  function destroyRelationship(rel) {
    if (isImplicit(rel)) {
      if (rel.graph.isReleasable(rel.identifier)) {
        removeCompletelyFromInverse(rel);
      }
      return;
    }
    rel.recordDataDidDematerialize();
    if (!rel.definition.inverseIsImplicit && !rel.definition.inverseIsAsync) {
      rel.state.isStale = true;
      rel.clear();

      // necessary to clear relationships in the ui from dematerialized records
      // hasMany is managed by InternalModel which calls `retreiveLatest` after
      // dematerializing the recordData instance.
      // but sync belongsTo require this since they don't have a proxy to update.
      // so we have to notify so it will "update" to null.
      // we should discuss whether we still care about this, probably fine to just
      // leave the ui relationship populated since the record is destroyed and
      // internally we've fully cleaned up.
      if (!rel.definition.isAsync) {
        if (isBelongsTo(rel)) {
          rel.notifyBelongsToChange();
        } else {
          rel.notifyHasManyChange();
        }
      }
    }
  }
  function removeCompletelyFromInverse(relationship) {
    // we actually want a union of members and canonicalMembers
    // they should be disjoint but currently are not due to a bug
    var seen = Object.create(null);
    var {
      identifier
    } = relationship;
    var {
      inverseKey
    } = relationship.definition;
    var unload = inverseIdentifier => {
      var id = inverseIdentifier.lid;
      if (seen[id] === undefined) {
        if (relationship.graph.has(inverseIdentifier, inverseKey)) {
          relationship.graph.get(inverseIdentifier, inverseKey).removeCompletelyFromOwn(identifier);
        }
        seen[id] = true;
      }
    };
    if (isBelongsTo(relationship)) {
      if (relationship.localState) {
        unload(relationship.localState);
      }
      if (relationship.remoteState) {
        unload(relationship.remoteState);
      }
      if (!relationship.definition.isAsync) {
        relationship.clear();
      }
      relationship.localState = null;
    } else if (isHasMany(relationship)) {
      relationship.members.forEach(unload);
      relationship.canonicalMembers.forEach(unload);
      if (!relationship.definition.isAsync) {
        relationship.clear();
        relationship.notifyHasManyChange();
      }
    } else {
      relationship.members.forEach(unload);
      relationship.canonicalMembers.forEach(unload);
      relationship.clear();
    }
  }

  /**
   * @module @ember-data/record-data
   */
  var nextBfsId = 1;
  var EMPTY_ITERATOR = {
    iterator() {
      return {
        next() {
          return {
            done: true,
            value: undefined
          };
        }
      };
    }
  };

  /**
    The default cache implementation used by ember-data. The cache
    is configurable and using a different implementation can be
    achieved by implementing the store's createRecordDataFor hook.

    @class RecordDataDefault
    @public
   */
  class RecordDataDefault {
    constructor(identifier, storeWrapper) {
      /*
        Iterates over the set of internal models reachable from `this` across exactly one
        relationship.
      */
      this._directlyRelatedRecordDatasIterable = () => {
        var graph = graphFor(this.storeWrapper);
        var initializedRelationships = graph.identifiers.get(this.identifier);
        if (!initializedRelationships) {
          return EMPTY_ITERATOR;
        }
        var initializedRelationshipsArr = Object.keys(initializedRelationships).map(key => initializedRelationships[key]).filter(rel => {
          return !isImplicit(rel);
        });
        var i = 0;
        var j = 0;
        var k = 0;
        var findNext = () => {
          while (i < initializedRelationshipsArr.length) {
            while (j < 2) {
              var members = j === 0 ? getLocalState(initializedRelationshipsArr[i]) : getRemoteState(initializedRelationshipsArr[i]);
              while (k < members.length) {
                var member = members[k++];
                if (member !== null) {
                  // TODO this can cause materialization
                  // do something to avoid that
                  return Private.recordDataFor(member);
                }
              }
              k = 0;
              j++;
            }
            j = 0;
            i++;
          }
          return undefined;
        };
        return {
          iterator() {
            return {
              next: () => {
                var value = findNext();
                return {
                  value,
                  done: value === undefined
                };
              }
            };
          }
        };
      };
      this.modelName = identifier.type;
      this.clientId = identifier.lid;
      this.id = identifier.id;
      this.identifier = identifier;
      this.storeWrapper = storeWrapper;
      this.isDestroyed = false;
      this._isNew = false;
      this._isDeleted = false;
      // Used during the mark phase of unloading to avoid checking the same internal
      // model twice in the same scan
      this._bfsId = 0;
      this.reset();
    }

    // PUBLIC API
    getResourceIdentifier() {
      return this.identifier;
    }
    pushData(data, calculateChange) {
      var changedKeys;
      if (this._isNew) {
        this._isNew = false;
        this.notifyStateChange();
      }
      if (calculateChange) {
        changedKeys = this._changedKeys(data.attributes);
      }
      polyfills.assign(this._data, data.attributes);
      if (this.__attributes) {
        // only do if we have attribute changes
        this._updateChangedAttributes();
      }
      if (data.relationships) {
        this._setupRelationships(data);
      }
      if (data.id) {
        this.id = coerceId(data.id);
      }
      return changedKeys;
    }
    willCommit() {
      this._inFlightAttributes = this._attributes;
      this._attributes = null;
    }
    hasChangedAttributes() {
      return this.__attributes !== null && Object.keys(this.__attributes).length > 0;
    }
    _clearErrors() {
      {
        if (this._errors) {
          this._errors = undefined;
          this.storeWrapper.notifyErrorsChange(this.modelName, this.id, this.clientId);
        }
      }
    }
    getErrors() {
      {
        var errors = this._errors || [];
        return errors;
      }
    }

    // this is a hack bc we don't have access to the state machine
    //   and relationships need this info and @runspired didn't see
    //   how to get it just yet from storeWrapper.
    isEmpty() {
      return this.__attributes === null && this.__inFlightAttributes === null && this.__data === null;
    }
    deleteRecord() {
      this._isDeleted = true;
      this.notifyStateChange();
    }
    isDeleted() {
      return this._isDeleted;
    }
    setIsDeleted(isDeleted) {
      this._isDeleted = isDeleted;
      if (this._isNew) {
        this._deletionConfirmed();
      }
      this.notifyStateChange();
    }
    isDeletionCommitted() {
      return this._isDeletionCommited;
    }
    reset() {
      this.__attributes = null;
      this.__inFlightAttributes = null;
      this.__data = null;
      this._errors = undefined;
    }
    _setupRelationships(data) {
      // TODO @runspired iterating by definitions instead of by payload keys
      // allows relationship payloads to be ignored silently if no relationship
      // definition exists. Ensure there's a test for this and then consider
      // moving this to an assertion. This check should possibly live in the graph.
      var relationships = this.storeWrapper.relationshipsDefinitionFor(this.modelName);
      var keys = Object.keys(relationships);
      for (var i = 0; i < keys.length; i++) {
        var relationshipName = keys[i];
        if (!data.relationships[relationshipName]) {
          continue;
        }
        var relationshipData = data.relationships[relationshipName];
        graphFor(this.storeWrapper).push({
          op: 'updateRelationship',
          record: this.identifier,
          field: relationshipName,
          value: relationshipData
        });
      }
    }

    /**
      Checks if the attributes which are considered as changed are still
      different to the state which is acknowledged by the server.
       This method is needed when data for the internal model is pushed and the
      pushed data might acknowledge dirty attributes as confirmed.
       @method updateChangedAttributes
      @private
     */
    _updateChangedAttributes() {
      var changedAttributes = this.changedAttributes();
      var changedAttributeNames = Object.keys(changedAttributes);
      var attrs = this._attributes;
      for (var i = 0, length = changedAttributeNames.length; i < length; i++) {
        var attribute = changedAttributeNames[i];
        var data = changedAttributes[attribute];
        var oldData = data[0];
        var newData = data[1];
        if (oldData === newData) {
          delete attrs[attribute];
        }
      }
    }

    /**
      Returns an object, whose keys are changed properties, and value is an
      [oldProp, newProp] array.
       @method changedAttributes
      @private
    */
    changedAttributes() {
      var oldData = this._data;
      var currentData = this._attributes;
      var inFlightData = this._inFlightAttributes;
      var newData = polyfills.assign({}, inFlightData, currentData);
      var diffData = Object.create(null);
      var newDataKeys = Object.keys(newData);
      for (var i = 0, length = newDataKeys.length; i < length; i++) {
        var key = newDataKeys[i];
        diffData[key] = [oldData[key], newData[key]];
      }
      return diffData;
    }
    isNew() {
      return this._isNew;
    }
    rollbackAttributes() {
      var dirtyKeys;
      this._isDeleted = false;
      if (this.hasChangedAttributes()) {
        dirtyKeys = Object.keys(this._attributes);
        this._attributes = null;
      }
      if (this.isNew()) {
        this.removeFromInverseRelationships();
        this._isDeleted = true;
        this._isNew = false;
      }
      this._inFlightAttributes = null;
      this._clearErrors();
      this.notifyStateChange();
      return dirtyKeys;
    }
    _deletionConfirmed() {
      this.removeFromInverseRelationships();
    }
    didCommit(data) {
      if (this._isDeleted) {
        this._deletionConfirmed();
        this._isDeletionCommited = true;
      }
      this._isNew = false;
      var newCanonicalAttributes = null;
      if (data) {
        if (data.id) {
          // didCommit provided an ID, notify the store of it
          this.storeWrapper.setRecordId(this.modelName, data.id, this.clientId);
          this.id = coerceId(data.id);
        }
        if (data.relationships) {
          this._setupRelationships(data);
        }
        newCanonicalAttributes = data.attributes || null;
      }
      var changedKeys = this._changedKeys(newCanonicalAttributes);
      polyfills.assign(this._data, this.__inFlightAttributes, newCanonicalAttributes);
      this._inFlightAttributes = null;
      this._updateChangedAttributes();
      this._clearErrors();
      this.notifyStateChange();
      return changedKeys;
    }
    notifyStateChange() {
      {
        this.storeWrapper.notifyStateChange(this.modelName, this.id, this.clientId);
      }
    }

    // get ResourceIdentifiers for "current state"
    getHasMany(key) {
      return graphFor(this.storeWrapper).get(this.identifier, key).getData();
    }

    // set a new "current state" via ResourceIdentifiers
    setDirtyHasMany(key, recordDatas) {
      graphFor(this.storeWrapper).update({
        op: 'replaceRelatedRecords',
        record: this.identifier,
        field: key,
        value: recordDatas.map(Private.recordIdentifierFor)
      });
    }

    // append to "current state" via RecordDatas
    addToHasMany(key, recordDatas, idx) {
      graphFor(this.storeWrapper).update({
        op: 'addToRelatedRecords',
        record: this.identifier,
        field: key,
        value: recordDatas.map(Private.recordIdentifierFor),
        index: idx
      });
    }

    // remove from "current state" via RecordDatas
    removeFromHasMany(key, recordDatas) {
      graphFor(this.storeWrapper).update({
        op: 'removeFromRelatedRecords',
        record: this.identifier,
        field: key,
        value: recordDatas.map(Private.recordIdentifierFor)
      });
    }
    commitWasRejected(identifier, errors) {
      var keys = Object.keys(this._inFlightAttributes);
      if (keys.length > 0) {
        var attrs = this._attributes;
        for (var i = 0; i < keys.length; i++) {
          if (attrs[keys[i]] === undefined) {
            attrs[keys[i]] = this._inFlightAttributes[keys[i]];
          }
        }
      }
      this._inFlightAttributes = null;
      {
        if (errors) {
          this._errors = errors;
        }
        this.storeWrapper.notifyErrorsChange(this.modelName, this.id, this.clientId);
      }
    }
    getBelongsTo(key) {
      return graphFor(this.storeWrapper).get(this.identifier, key).getData();
    }
    setDirtyBelongsTo(key, recordData) {
      graphFor(this.storeWrapper).update({
        op: 'replaceRelatedRecord',
        record: this.identifier,
        field: key,
        value: recordData ? Private.recordIdentifierFor(recordData) : null
      });
    }
    setDirtyAttribute(key, value) {
      var originalValue;
      // Add the new value to the changed attributes hash
      this._attributes[key] = value;
      if (key in this._inFlightAttributes) {
        originalValue = this._inFlightAttributes[key];
      } else {
        originalValue = this._data[key];
      }
      // If we went back to our original value, we shouldn't keep the attribute around anymore
      if (value === originalValue) {
        delete this._attributes[key];
      }
    }

    // internal set coming from the model
    __setId(id) {
      if (this.id !== id) {
        this.id = id;
      }
    }
    getAttr(key) {
      if (key in this._attributes) {
        return this._attributes[key];
      } else if (key in this._inFlightAttributes) {
        return this._inFlightAttributes[key];
      } else {
        return this._data[key];
      }
    }
    hasAttr(key) {
      return key in this._attributes || key in this._inFlightAttributes || key in this._data;
    }
    unloadRecord() {
      if (this.isDestroyed) {
        return;
      }
      graphFor(this.storeWrapper).unload(this.identifier);
      this.reset();
      if (!this._scheduledDestroy) {
        this._scheduledDestroy = runloop._backburner.schedule('destroy', this, '_cleanupOrphanedRecordDatas');
      }
    }
    _cleanupOrphanedRecordDatas() {
      var relatedRecordDatas = this._allRelatedRecordDatas();
      if (areAllModelsUnloaded(relatedRecordDatas)) {
        // we don't have a backburner queue yet since
        // we scheduled this into ember's destroy
        // disconnectRecord called from destroy will teardown
        // relationships. We do this to queue that.
        this.storeWrapper._store._backburner.join(() => {
          for (var i = 0; i < relatedRecordDatas.length; ++i) {
            var recordData = relatedRecordDatas[i];
            if (!recordData.isDestroyed) {
              // TODO @runspired we do not currently destroy RecordData instances *except* via this relationship
              // traversal. This seems like an oversight since the store should be able to notify destroy.
              Private.removeRecordDataFor(recordData.identifier);
              recordData.destroy();
            }
          }
        });
      }
      this._scheduledDestroy = null;
    }
    destroy() {
      this.isDestroyed = true;
      this.storeWrapper.disconnectRecord(this.modelName, this.id, this.clientId);
    }
    isRecordInUse() {
      return this.storeWrapper.isRecordInUse(this.modelName, this.id, this.clientId);
    }
    /*
      Computes the set of internal models reachable from this internal model.
       Reachability is determined over the relationship graph (ie a graph where
      nodes are internal models and edges are belongs to or has many
      relationships).
       Returns an array including `this` and all internal models reachable
      from `this`.
    */
    _allRelatedRecordDatas() {
      var array = [];
      var queue = [];
      var bfsId = nextBfsId++;
      queue.push(this);
      this._bfsId = bfsId;
      while (queue.length > 0) {
        var node = queue.shift();
        array.push(node);
        var iterator = this._directlyRelatedRecordDatasIterable().iterator();
        for (var obj = iterator.next(); !obj.done; obj = iterator.next()) {
          var recordData = obj.value;
          if (recordData && recordData instanceof RecordDataDefault) {
            if (recordData._bfsId < bfsId) {
              queue.push(recordData);
              recordData._bfsId = bfsId;
            }
          }
        }
      }
      return array;
    }
    isAttrDirty(key) {
      if (this._attributes[key] === undefined) {
        return false;
      }
      var originalValue;
      if (this._inFlightAttributes[key] !== undefined) {
        originalValue = this._inFlightAttributes[key];
      } else {
        originalValue = this._data[key];
      }
      return originalValue !== this._attributes[key];
    }
    get _attributes() {
      if (this.__attributes === null) {
        this.__attributes = Object.create(null);
      }
      return this.__attributes;
    }
    set _attributes(v) {
      this.__attributes = v;
    }
    get _data() {
      if (this.__data === null) {
        this.__data = Object.create(null);
      }
      return this.__data;
    }
    set _data(v) {
      this.__data = v;
    }
    get _inFlightAttributes() {
      if (this.__inFlightAttributes === null) {
        this.__inFlightAttributes = Object.create(null);
      }
      return this.__inFlightAttributes;
    }
    set _inFlightAttributes(v) {
      this.__inFlightAttributes = v;
    }

    /**
     * Receives options passed to `store.createRecord` and is given the opportunity
     * to handle them.
     *
     * The return value is an object of options to pass to `Record.create()`
     *
     * @name _initRecordCreateOptions
     * @param options
     * @private
     */
    _initRecordCreateOptions(options) {
      var createOptions = {};
      if (options !== undefined) {
        var {
          modelName,
          storeWrapper,
          identifier
        } = this;
        var attributeDefs = storeWrapper.attributesDefinitionFor(modelName);
        var relationshipDefs = storeWrapper.relationshipsDefinitionFor(modelName);
        var graph = graphFor(storeWrapper);
        var propertyNames = Object.keys(options);
        for (var i = 0; i < propertyNames.length; i++) {
          var name = propertyNames[i];
          var propertyValue = options[name];
          if (name === 'id') {
            this.id = propertyValue;
            continue;
          }
          var fieldType = relationshipDefs[name] || attributeDefs[name];
          var kind = fieldType !== undefined ? fieldType.kind : null;
          var relationship = void 0;
          switch (kind) {
            case 'attribute':
              this.setDirtyAttribute(name, propertyValue);
              break;
            case 'belongsTo':
              this.setDirtyBelongsTo(name, propertyValue);
              relationship = graph.get(identifier, name);
              relationship.state.hasReceivedData = true;
              relationship.state.isEmpty = false;
              break;
            case 'hasMany':
              this.setDirtyHasMany(name, propertyValue);
              relationship = graph.get(identifier, name);
              relationship.state.hasReceivedData = true;
              relationship.state.isEmpty = false;
              break;
            default:
              // reflect back (pass-thru) unknown properties
              createOptions[name] = propertyValue;
          }
        }
      }
      return createOptions;
    }

    /*
      TODO IGOR AND DAVID this shouldn't be public
     This method should only be called by records in the `isNew()` state OR once the record
     has been deleted and that deletion has been persisted.
      It will remove this record from any associated relationships.
      If `isNew` is true (default false), it will also completely reset all
      relationships to an empty state as well.
       @method removeFromInverseRelationships
      @param {Boolean} isNew whether to unload from the `isNew` perspective
      @private
     */
    removeFromInverseRelationships() {
      graphFor(this.storeWrapper).push({
        op: 'deleteRecord',
        record: this.identifier,
        isNew: this.isNew()
      });
    }
    clientDidCreate() {
      this._isNew = true;
    }

    /*
      Ember Data has 3 buckets for storing the value of an attribute on an internalModel.
       `_data` holds all of the attributes that have been acknowledged by
      a backend via the adapter. When rollbackAttributes is called on a model all
      attributes will revert to the record's state in `_data`.
       `_attributes` holds any change the user has made to an attribute
      that has not been acknowledged by the adapter. Any values in
      `_attributes` are have priority over values in `_data`.
       `_inFlightAttributes`. When a record is being synced with the
      backend the values in `_attributes` are copied to
      `_inFlightAttributes`. This way if the backend acknowledges the
      save but does not return the new state Ember Data can copy the
      values from `_inFlightAttributes` to `_data`. Without having to
      worry about changes made to `_attributes` while the save was
      happenign.
        Changed keys builds a list of all of the values that may have been
      changed by the backend after a successful save.
       It does this by iterating over each key, value pair in the payload
      returned from the server after a save. If the `key` is found in
      `_attributes` then the user has a local changed to the attribute
      that has not been synced with the server and the key is not
      included in the list of changed keys.
    
      If the value, for a key differs from the value in what Ember Data
      believes to be the truth about the backend state (A merger of the
      `_data` and `_inFlightAttributes` objects where
      `_inFlightAttributes` has priority) then that means the backend
      has updated the value and the key is added to the list of changed
      keys.
       @method _changedKeys
      @private
    */
    /*
        TODO IGOR DAVID
        There seems to be a potential bug here, where we will return keys that are not
        in the schema
    */
    _changedKeys(updates) {
      var changedKeys = [];
      if (updates) {
        var original, i, value, key;
        var keys = Object.keys(updates);
        var length = keys.length;
        var hasAttrs = this.hasChangedAttributes();
        var attrs;
        if (hasAttrs) {
          attrs = this._attributes;
        }
        original = polyfills.assign(Object.create(null), this._data, this.__inFlightAttributes);
        for (i = 0; i < length; i++) {
          key = keys[i];
          value = updates[key];

          // A value in _attributes means the user has a local change to
          // this attributes. We never override this value when merging
          // updates from the backend so we should not sent a change
          // notification if the server value differs from the original.
          if (hasAttrs === true && attrs[key] !== undefined) {
            continue;
          }
          if (!utils.isEqual(original[key], value)) {
            changedKeys.push(key);
          }
        }
      }
      return changedKeys;
    }
    toString() {
      return `<${this.modelName}:${this.id}>`;
    }
  }
  function areAllModelsUnloaded(recordDatas) {
    for (var i = 0; i < recordDatas.length; ++i) {
      if (recordDatas[i].isRecordInUse()) {
        return false;
      }
    }
    return true;
  }
  function getLocalState(rel) {
    if (rel.definition.kind === 'belongsTo') {
      return rel.localState ? [rel.localState] : [];
    }
    return rel.currentState;
  }
  function getRemoteState(rel) {
    if (rel.definition.kind === 'belongsTo') {
      return rel.remoteState ? [rel.remoteState] : [];
    }
    return rel.canonicalState;
  }

  exports.BelongsToRelationship = BelongsToRelationship;
  exports.ManyRelationship = ManyRelationship;
  exports.RecordData = RecordDataDefault;
  exports.Relationship = ImplicitRelationship;
  exports.graphFor = graphFor;
  exports.peekGraph = peekGraph;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
