define("shared/components/global/tooltip", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "@ember/utils", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _utils, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="shared-tooltip cursor-pointer w-3 h-3 {{if @customClass @customClass}}">
    <div {{on "mouseenter" this.onMouseEnter}} class="group relative {{if @direction @direction 'right'}}">
      {{#if @customIconClass}}
        <div data-testing-id={{@dataTestingIdOnClick}} class={{@customIconClass}} role="button" onmouseover={{this.sendHeapCustomEvent}}>
        </div>
      {{else}}
        <div data-testing-id={{@dataTestingIdOnClick}} class="shared-tooltip-icon-container w-3 h-3 rounded-full flex items-center justify-center {{if @iconStyle @iconStyle 'bg-bp-text-icons-disabled'}}" role="button" onmouseover={{this.sendHeapCustomEvent}}>
          <i class="shared-tooltip-icon {{if @icon @icon 'icon-question'}} {{if @iconFontSize @iconFontSize 'text-2xs text-white'}}"/>
        </div>
      {{/if}}
      <div class="tooltip-content group-hover:block hidden absolute top-1 rounded-lg shadow-bp-lg p-2 z-50 {{if @minWidth @minWidth 'min-w-70'}} {{if @width @width}} {{if @customContainerClass @customContainerClass}} {{if @background @background 'bg-bp-base-onSurface'}}">
        <span class="{{if @fontSize @fontSize 'text-base' }} {{if @textColor @textColor 'text-bp-text-icons-onDark'}}">{{@content}}</span>
      </div>
      <div class="tooltip-arrow group-hover:block hidden"/>
    </div>
  </div>
  
  */
  {
    "id": "AOUjTKtF",
    "block": "[[[10,0],[15,0,[29,[\"shared-tooltip cursor-pointer w-3 h-3 \",[52,[30,1],[30,1]]]]],[12],[1,\"\\n  \"],[11,0],[16,0,[29,[\"group relative \",[52,[30,2],[30,2],\"right\"]]]],[4,[38,1],[\"mouseenter\",[30,0,[\"onMouseEnter\"]]],null],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"      \"],[10,0],[15,\"data-testing-id\",[30,4]],[15,0,[30,3]],[14,\"role\",\"button\"],[15,\"onmouseover\",[30,0,[\"sendHeapCustomEvent\"]]],[12],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[15,\"data-testing-id\",[30,4]],[15,0,[29,[\"shared-tooltip-icon-container w-3 h-3 rounded-full flex items-center justify-center \",[52,[30,5],[30,5],\"bg-bp-text-icons-disabled\"]]]],[14,\"role\",\"button\"],[15,\"onmouseover\",[30,0,[\"sendHeapCustomEvent\"]]],[12],[1,\"\\n        \"],[10,\"i\"],[15,0,[29,[\"shared-tooltip-icon \",[52,[30,6],[30,6],\"icon-question\"],\" \",[52,[30,7],[30,7],\"text-2xs text-white\"]]]],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[10,0],[15,0,[29,[\"tooltip-content group-hover:block hidden absolute top-1 rounded-lg shadow-bp-lg p-2 z-50 \",[52,[30,8],[30,8],\"min-w-70\"],\" \",[52,[30,9],[30,9]],\" \",[52,[30,10],[30,10]],\" \",[52,[30,11],[30,11],\"bg-bp-base-onSurface\"]]]],[12],[1,\"\\n      \"],[10,1],[15,0,[29,[[52,[30,12],[30,12],\"text-base\"],\" \",[52,[30,13],[30,13],\"text-bp-text-icons-onDark\"]]]],[12],[1,[30,14]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"tooltip-arrow group-hover:block hidden\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@customClass\",\"@direction\",\"@customIconClass\",\"@dataTestingIdOnClick\",\"@iconStyle\",\"@icon\",\"@iconFontSize\",\"@minWidth\",\"@width\",\"@customContainerClass\",\"@background\",\"@fontSize\",\"@textColor\",\"@content\"],false,[\"if\",\"on\"]]",
    "moduleName": "shared/components/global/tooltip.hbs",
    "isStrictMode": false
  });
  let TooltipComponent = _exports.default = (_class = class TooltipComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "heap", _descriptor, this);
    }
    onMouseEnter() {
      this.args.onTooltipOpen?.();
    }
    sendHeapCustomEvent() {
      if ((0, _utils.isPresent)(this.args.dataTestingIdOnClick)) {
        this.heap.track('tooltip_mouse_over', {
          tooltip_id: this.args.dataTestingIdOnClick
        });
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "heap", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onMouseEnter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onMouseEnter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sendHeapCustomEvent", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sendHeapCustomEvent"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TooltipComponent);
});