define("shared/components/form-elements/state-selection/state-selection", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/utils", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _service, _utils, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <FormElements::SelectionInput @selectAction={{this.select}}
                                @validType={{@validType}}
                                @inputLabel={{@inputLabel}}
                                @initialValue={{@initialValue}}
                                @list={{this.regionsList}}
                                @disabled={{this.noRegions}}
                                @elementId={{@elementId}}
                                @customClass={{@customClass}}
                                @withErrorCheck={{@withErrorCheck}}
  />
  
  */
  {
    "id": "MGVavtVT",
    "block": "[[[8,[39,0],null,[[\"@selectAction\",\"@validType\",\"@inputLabel\",\"@initialValue\",\"@list\",\"@disabled\",\"@elementId\",\"@customClass\",\"@withErrorCheck\"],[[30,0,[\"select\"]],[30,1],[30,2],[30,3],[30,0,[\"regionsList\"]],[30,0,[\"noRegions\"]],[30,4],[30,5],[30,6]]],null],[1,\"\\n\"]],[\"@validType\",\"@inputLabel\",\"@initialValue\",\"@elementId\",\"@customClass\",\"@withErrorCheck\"],false,[\"form-elements/selection-input\"]]",
    "moduleName": "shared/components/form-elements/state-selection/state-selection.hbs",
    "isStrictMode": false
  });
  let StateSelectionComponent = _exports.default = (_class = class StateSelectionComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "countries", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
    }
    get selectedCountry() {
      return this.selectedCountryByCode(this.args.selectedCountry);
    }
    selectedCountryByCode(code) {
      if ((0, _utils.isBlank)(code)) return null;
      const foundCountry = this.countries.countries.find(country => country.code === code);
      return foundCountry ? foundCountry : null;
    }
    get regionsList() {
      return this.selectedCountry?.regions.map(region => this.intl.t(`countries.${this.selectedCountry.code}.regions.${region}`).toString());
    }
    get noRegions() {
      return (0, _utils.isBlank)(this.selectedCountry?.regions);
    }
    select(value) {
      if ((0, _utils.isBlank)(this.args.index)) {
        return this.args.selectAction(value);
      } else {
        this.args.selectAction(value, this.args.index);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "countries", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "select", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, StateSelectionComponent);
});