define("shared/components/print-store/apparel-preview", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "shared/print-store/apparel-color-list", "@ember/template-factory"], function (_exports, _component, _component2, _service, _apparelColorList, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="apparel-preview justify-self-center rounded relative flex flex-col w-full h-full {{@customClass}}" data-testing-id={{@dataTesting}}>
    <div class="mockup flex max-w-full max-h-full m-auto w-full h-full">
      <svg viewBox="5 133 516 248" height="100%" width="100%">
        <defs>
          <filter id="filter-{{@color}}" type="matrix">
            <feColorMatrix type="matrix" values={{this.transformationMatrix}}/>
          </filter>
        </defs>
        <image xlink:href="/images/components/apparel/{{@type}}/{{@gender}}-grey-bg.png"/>
        <image class="mask-{{@color}}" xlink:href="/images/components/apparel/{{@type}}/{{@gender}}-mask.png"/>
        {{#if (eq @layout 'side_layout')}}
          <foreignObject width="80" height="40" x="270" y="155">
            <Global::LogoImage @brandVersion={{@brandVersion}} @embedSvg={{true}}/>
          </foreignObject>
        {{else}}
          <foreignObject width="195" height="85" x="155" y="120">
            <Global::LogoImage @brandVersion={{@brandVersion}} @embedSvg={{true}}/>
          </foreignObject>
        {{/if}}
      </svg>
    </div>
  </div>
  
  */
  {
    "id": "6P7+4BkC",
    "block": "[[[10,0],[15,0,[29,[\"apparel-preview justify-self-center rounded relative flex flex-col w-full h-full \",[30,1]]]],[15,\"data-testing-id\",[30,2]],[12],[1,\"\\n  \"],[10,0],[14,0,\"mockup flex max-w-full max-h-full m-auto w-full h-full\"],[12],[1,\"\\n    \"],[10,\"svg\"],[14,\"viewBox\",\"5 133 516 248\"],[14,\"height\",\"100%\"],[14,\"width\",\"100%\"],[12],[1,\"\\n      \"],[10,\"defs\"],[12],[1,\"\\n        \"],[10,\"filter\"],[15,1,[29,[\"filter-\",[30,3]]]],[14,4,\"matrix\"],[12],[1,\"\\n          \"],[10,\"feColorMatrix\"],[15,\"values\",[30,0,[\"transformationMatrix\"]]],[14,4,\"matrix\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"image\"],[15,\"xlink:href\",[29,[\"/images/components/apparel/\",[30,4],\"/\",[30,5],\"-grey-bg.png\"]],\"http://www.w3.org/1999/xlink\"],[12],[13],[1,\"\\n      \"],[10,\"image\"],[15,0,[29,[\"mask-\",[30,3]]]],[15,\"xlink:href\",[29,[\"/images/components/apparel/\",[30,4],\"/\",[30,5],\"-mask.png\"]],\"http://www.w3.org/1999/xlink\"],[12],[13],[1,\"\\n\"],[41,[28,[37,1],[[30,6],\"side_layout\"],null],[[[1,\"        \"],[10,\"foreignObject\"],[14,\"width\",\"80\"],[14,\"height\",\"40\"],[14,\"x\",\"270\"],[14,\"y\",\"155\"],[12],[1,\"\\n          \"],[8,[39,2],null,[[\"@brandVersion\",\"@embedSvg\"],[[30,7],true]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,\"foreignObject\"],[14,\"width\",\"195\"],[14,\"height\",\"85\"],[14,\"x\",\"155\"],[14,\"y\",\"120\"],[12],[1,\"\\n          \"],[8,[39,2],null,[[\"@brandVersion\",\"@embedSvg\"],[[30,7],true]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@customClass\",\"@dataTesting\",\"@color\",\"@type\",\"@gender\",\"@layout\",\"@brandVersion\"],false,[\"if\",\"eq\",\"global/logo-image\"]]",
    "moduleName": "shared/components/print-store/apparel-preview.hbs",
    "isStrictMode": false
  });
  let ApparelPreviewComponent = _exports.default = (_class = class ApparelPreviewComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "highResLogos", _descriptor, this);
      _initializerDefineProperty(this, "heap", _descriptor2, this);
    }
    get transformationMatrix() {
      const color = tinycolor(this.shadeColor);
      return `0 0 0 0 ${color._r / 255}  0 0 0 0 ${color._g / 255}  0 0 0 0 ${color._b / 255}  0 0 0 1 0`;
    }
    get shadeColor() {
      return _apparelColorList.default[this.args.color]['mask'];
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "highResLogos", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "heap", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ApparelPreviewComponent);
});