define("shared/components/global/dot-lottie-player", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@lottiefiles/dotlottie-web", "@ember/template-factory"], function (_exports, _component, _component2, _object, _dotlottieWeb, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <canvas class={{@customClass}} {{did-insert this.initializePlayer}}/>
  
  */
  {
    "id": "gFJeevOa",
    "block": "[[[11,\"canvas\"],[16,0,[30,1]],[4,[38,0],[[30,0,[\"initializePlayer\"]]],null],[12],[13],[1,\"\\n\"]],[\"@customClass\"],false,[\"did-insert\"]]",
    "moduleName": "shared/components/global/dot-lottie-player.hbs",
    "isStrictMode": false
  });
  let DotLottiePlayerComponent = _exports.default = (_class = class DotLottiePlayerComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "player", null);
    }
    initializePlayer(element) {
      this.player = new _dotlottieWeb.DotLottie({
        canvas: element,
        src: this.args.src,
        autoplay: this.args.autoplay ?? true,
        loop: this.args.loop ?? true
      });
      const duration = this.args.duration ?? 3000; // Default to 3 seconds
      setTimeout(() => {
        this.removeCanvas(element);
      }, duration);
    }
    removeCanvas(canvasElement) {
      if (this.player) {
        this.player.destroy();
      }
      if (canvasElement && canvasElement.parentNode) {
        canvasElement.parentNode.removeChild(canvasElement);
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "initializePlayer", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "initializePlayer"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DotLottiePlayerComponent);
});