define("shared/components/business-mailbox/mailbox-pending-setup-users", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="cp-mailbox-pending-setup-users flex flex-col mx-4 my-2 bg-white rounded w-full p-ms max-w-144 xl:max-w-180 border border-solid border-bp-base-brand">
    <div>
      <div class="flex flex-col lg:flex-row justify-between items-start lg:items-center w-full" data-testing-id="my-mailbox-complete-setup-text">
        <span class="flex items-center">
          <span class="text-sm font-bold mr-2 mb-2 lg:mb-0">
            {{#if (gt @pendingSetupUsersAmount 1)}}
              {{t 'business_mailbox.my_mailbox.require_setup.multi_users' amount=@pendingSetupUsersAmount}}
            {{else}}
              {{t 'business_mailbox.my_mailbox.require_setup.single_user'}}
            {{/if}}
          </span>
          <button type="button" {{on 'click' this.openInfoModal}} data-testing-id="my-mailbox-info-banner" class="flex self-center text-2xs mb-2 lg:mb-0">
            <i class="icon-question-circle font-normal cursor-pointer"/>
          </button>
        </span>
        <LinkTo class="complete-setup-btn external-link text-sm" @model={{@selectedBrand.primaryBrandVersionId}} @route="setup-users" @query={{hash newUsersAmount=@pendingSetupUsersAmount}}
                data-testing-id="my-mailbox-complete-setup-banner" type="button">
          {{t 'business_mailbox.my_mailbox.require_setup.btn'}}
        </LinkTo>
      </div>
    </div>
  </div>
  
  */
  {
    "id": "bFaL4/ad",
    "block": "[[[10,0],[14,0,\"cp-mailbox-pending-setup-users flex flex-col mx-4 my-2 bg-white rounded w-full p-ms max-w-144 xl:max-w-180 border border-solid border-bp-base-brand\"],[12],[1,\"\\n  \"],[10,0],[12],[1,\"\\n    \"],[10,0],[14,0,\"flex flex-col lg:flex-row justify-between items-start lg:items-center w-full\"],[14,\"data-testing-id\",\"my-mailbox-complete-setup-text\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"flex items-center\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"text-sm font-bold mr-2 mb-2 lg:mb-0\"],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,1],1],null],[[[1,\"            \"],[1,[28,[35,2],[\"business_mailbox.my_mailbox.require_setup.multi_users\"],[[\"amount\"],[[30,1]]]]],[1,\"\\n\"]],[]],[[[1,\"            \"],[1,[28,[35,2],[\"business_mailbox.my_mailbox.require_setup.single_user\"],null]],[1,\"\\n\"]],[]]],[1,\"        \"],[13],[1,\"\\n        \"],[11,\"button\"],[24,\"data-testing-id\",\"my-mailbox-info-banner\"],[24,0,\"flex self-center text-2xs mb-2 lg:mb-0\"],[24,4,\"button\"],[4,[38,3],[\"click\",[30,0,[\"openInfoModal\"]]],null],[12],[1,\"\\n          \"],[10,\"i\"],[14,0,\"icon-question-circle font-normal cursor-pointer\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[39,4],[[24,0,\"complete-setup-btn external-link text-sm\"],[24,\"data-testing-id\",\"my-mailbox-complete-setup-banner\"],[24,4,\"button\"]],[[\"@model\",\"@route\",\"@query\"],[[30,2,[\"primaryBrandVersionId\"]],\"setup-users\",[28,[37,5],null,[[\"newUsersAmount\"],[[30,1]]]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,2],[\"business_mailbox.my_mailbox.require_setup.btn\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@pendingSetupUsersAmount\",\"@selectedBrand\"],false,[\"if\",\"gt\",\"t\",\"on\",\"link-to\",\"hash\"]]",
    "moduleName": "shared/components/business-mailbox/mailbox-pending-setup-users.hbs",
    "isStrictMode": false
  });
  let MailboxPendingSetupUsersComponent = _exports.default = (_class = class MailboxPendingSetupUsersComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "businessMailbox", _descriptor, this);
      _initializerDefineProperty(this, "modal", _descriptor2, this);
    }
    openInfoModal() {
      this.modal.open('modals/mailbox-complete-setup-modal', {
        usersAmount: this.args.pendingSetupUsersAmount
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "businessMailbox", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "openInfoModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openInfoModal"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MailboxPendingSetupUsersComponent);
});