define("shared/llc/state_field_lock_mapping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    ky: 'Kentucky',
    la: 'Louisiana',
    md: 'Maryland',
    ma: 'Massachusetts',
    ne: 'Nebraska',
    nm: 'New Mexico',
    ny: 'New York',
    sc: 'South Carolina',
    tn: 'Tennessee',
    vt: 'Vermont',
    wv: 'West Virginia'
  };
});